import { FormField, InputTypes } from 'components/create-consignment/create-consignment.types';
import { TableColumn } from './settings.types';

export enum SettingPages {
    HeaderMapping = 'HEADER MAPPING',
    PieceHeaderMapping = 'PIECE HEADER MAPPING',
    ProductMapping = 'PRODUCT CODE MAPPING',
    AccountSetting = 'ACCOUNT SETTINGS',
    ChannelIntegration = 'CHANNEL INTEGRATION',
}

export const subAccountColumns: TableColumn[] = [{
    key: 'index',
    pretty_name: 'serialNumber',
}, {
    key: 'code',
    pretty_name: 'account_code',
}, {
    key: 'name',
    pretty_name: 'account_name',
}, {
    key: 'username',
    pretty_name: 'account_username',
}, {
    key: 'phone_list',
    pretty_name: 'account_contact_number',
}, {
    key: 'is_active',
    pretty_name: 'account_status',
}, {
    key: 'created_at',
    pretty_name: 'account_creation_date',
}, {
    key: 'action',
    pretty_name: 'action',
}];

export const productColumns: TableColumn[] = [{
    key: 'index',
    pretty_name: 'SR.NO',
}, {
    key: 'code',
    pretty_name: 'PRODUCT CODE',
}, {
    key: 'name',
    pretty_name: 'PRODUCT NAME',
}, {
    key: 'dimensions',
    pretty_name: 'DIMENSIONS',
}, {
    key: 'weight',
    pretty_name: 'WEIGHT',
}, {
    key: 'declaredValue',
    pretty_name: 'Declared Value',
}, {
    key: 'description',
    pretty_name: 'Description',
}, {
    key: 'actions',
    pretty_name: 'Actions',
}];

export const headerColumns: TableColumn[] = [{
    key: 'index',
    pretty_name: 'SR.NO',
}, {
    key: 'name',
    pretty_name: 'HEADER NAME',
}, {
    key: 'positionAlpha',
    pretty_name: 'COLUMN(XLS)',
}, {
    key: 'useDefault',
    pretty_name: 'USE DEFAULT',
}, {
    key: 'default',
    pretty_name: 'DEFAULT VALUE',
}];

export const channelintegrationFormFields: Record<string, FormField> = {
    storeUrl: {
        label: 'Store Url',
        placeholder: 'Enter Store Url',
        type: InputTypes.Input,
        key: 'store_url',
        valuePropName: 'value',
        width: '80%',
        titleWidth: '24%',
    },
    appName: {
        label: 'Store Name',
        placeholder: 'Enter Store NAME',
        type: InputTypes.Input,
        key: 'app_name',
        valuePropName: 'value',
        width: '80%',
        titleWidth: '24%',
    },
    appId: {
        label: 'App Id',
        placeholder: 'ENTER APP ID ',
        type: InputTypes.Input,
        key: 'api_key',
        valuePropName: 'value',
        width: '60%',

    },
    appsecret: {
        label: 'App Secret',
        placeholder: 'ENTER APP SECRET',
        type: InputTypes.Input,
        key: 'api_secret',
        valuePropName: 'value',
        width: '60%',
    },
    accesstoken: {
        label: 'Access Token',
        placeholder: 'Enter Access Token',
        type: InputTypes.Input,
        key: 'access_token',
        valuePropName: 'value',
        width: '60%',
    },
    redirectionUrl: {
        label: 'Redirection URL',
        placeholder: 'URL will apear here',
        type: InputTypes.Input,
        key: 'redirection_Url',
        valuePropName: 'value',
        width: '90%',
        disabled: true,
    },
    defaultWeight: {
        label: 'Default Weight',
        defaultValue: '1',
        type: InputTypes.Input,
        key: 'default_weight',
        valuePropName: 'value',
        width: '9rem',
        disabled: false,
    },
    defaultWeightUnit: {
        label: '',
        defaultValue: 'kg',
        type: InputTypes.Input,
        key: 'default_weight_unit',
        valuePropName: 'value',
        width: '2.75rem',
        disabled: true,
    },
    defaultHeight: {
        label: 'Default Dimensions',
        defaultValue: '1',
        type: InputTypes.Input,
        key: 'default_height',
        valuePropName: 'value',
        width: '3rem',
        disabled: false,
    },
    defaultLength: {
        label: '',
        defaultValue: '1',
        type: InputTypes.Input,
        key: 'default_length',
        valuePropName: 'value',
        width: '3rem',
        disabled: false,
    },
    defaultWidth: {
        label: '',
        defaultValue: '1',
        type: InputTypes.Input,
        key: 'default_width',
        valuePropName: 'value',
        width: '3rem',
        disabled: false,
    },
    defaultVolumeUnit: {
        label: '',
        defaultValue: 'cm',
        type: InputTypes.Input,
        key: 'default_volume_unit',
        valuePropName: 'value',
        width: '3rem',
        disabled: true,
    },
    orderPullStep: {
        label: 'Order Pull Step',
        type: InputTypes.Select,
        mode: 'multiple',
        key: 'order_pull_step',
        valuePropName: 'value',
        defaultValue: ['ORDER_CREATION'],
        width: '12rem',
        disabled: false,
        options: [
            {
                label: 'Order FulFillment',
                value: 'ORDER_FULFILLMENT',
            },
            {
                label: 'Order Creation',
                value: 'ORDER_CREATION',
            },
        ],
    },
    autoShipment: {
        label: 'Auto Consignment Creation ',
        type: InputTypes.Switch,
        key: 'auto_shipment',
        valuePropName: 'value',
        width: '15%',
        titleWidth: '100%',
    },
    defaultDomesticServiceType: {
        label: 'Default Service Type',
        type: InputTypes.Select,
        key: 'default_domestic_service_type',
        valuePropName: 'value',
        placeholder: 'Select Service Type',
        width: '12rem',
        options: [
            {
                label: 'Standard',
                value: 'Standard',
            },
        ],
    },
    defaultLoadType: {
        label: 'Default Load Type',
        type: InputTypes.Select,
        key: 'default_load_type',
        valuePropName: 'value',
        defaultValue: 'Non-Document',
        width: '12rem',
        disabled: true,
        options: [
            {
                label: 'Document',
                value: 'Document',
            },
            {
                label: 'Non-Document',
                value: 'Non-Document',
            },
        ],
    },
    defaultContentType: {
        label: 'Default Content Type',
        type: InputTypes.Select,
        key: 'default_content_type',
        valuePropName: 'value',
        defaultValue: 'Others',
        width: '12rem',
        disabled: true,
        options: [
            {
                label: 'Goods',
                value: 'Goods',
            },
            {
                label: 'Others',
                value: 'Others',
            },
        ],
    },
    callbackUrl: {
        label: 'Callback URL',
        placeholder: 'URL will apear here',
        type: InputTypes.Input,
        key: 'callback_url',
        valuePropName: 'value',
        width: '90%',
        disabled: true,
    },
    webhookurl: {
        label: 'Webhook URL',
        placeholder: 'URL will apear here',
        type: InputTypes.Input,
        key: 'webhook_url',
        valuePropName: 'value',
        width: '90%',
        disabled: true,
    },
    name: {
        label: 'Name',
        placeholder: 'ENTER NAME',
        type: InputTypes.Input,
        key: 'name',
        valuePropName: 'value',
        width: '230px',
    },
    email: {
        label: 'Email',
        placeholder: 'ENTER EMAIL',
        type: InputTypes.Input,
        key: 'email',
        valuePropName: 'value',
        width: '379px',
    },
    ReciepientType: {
        label: 'Reciepient Type',
        placeholder: 'TO',
        type: InputTypes.Select,
        key: 'Reciepient_type',
        valuePropName: 'value',
        width: '100%',
    },
    trackingUpdates: {
        label: 'Status Update Webhook',
        type: InputTypes.Switch,
        key: 'send_tracking_updates_to_shopify',
        valuePropName: 'value',
        width: '15%',
        titleWidth: '100%',
    },
};
