// import { NAVBAR_HEIGHT } from 'library/globals';
// import { FileExcelFilled } from '@ant-design/icons';
import { ThemeType } from 'theme/jss-types';
import { commonStyleSheet } from '../../library/common-styles';

const commonStyles = (theme: ThemeType) => ({
    ...commonStyleSheet(theme),
    line: {
        height: 1,
        margin: '12px 0px',
        width: '100%',
        backgroundColor: '#EDEDED',
    },
    main: {
        width: '100%',
        overflow: 'auto',
        maxHeight: 'calc(100vh - 210px)',
        minHeight: 'calc(100vh - 210px)',
        // paddingBottom: 100,
        '& .ant-input, .ant-input-affix-wrapper': {
            boxSizing: 'border-box',
            height: 32,
            border: '1px solid #999999',
            borderRadius: 4,
            marginRight: 2,
        },
        '& .ant-input-number-input': {
            boxSizing: 'border-box',
            height: 32,
            border: '1px solid #999999',
            borderRadius: 4,
            marginRight: 2,
        },
        '& .ant-select:not(.ant-select-customize-input) .ant-select-selector': {
            boxSizing: 'border-box',
            height: 32,
            border: '1px solid #999999',
            borderRadius: 4,
            marginRight: 2,
        },
        '& .ant-checkbox-group-item:last-child': {
            display: 'flex',
            color: '#111111',
            fontFamily: 'Open Sans',
            fontSize: 14,
            fontWeight: 600,
            letterSpacing: 0,
            // marginLeft: 24,
        },
        '& .ant-form-item-control-input': {
            minHeight: 24,
        },
        '& .ant-form-item': {
            marginBottom: 0,
        },
        '& .ant-radio-wrapper': {
            display: 'flex',
            color: '#111111',
            fontFamily: 'Open Sans',
            fontSize: 14,
            fontWeight: 600,
            letterSpacing: 0,
            margin: '4px 24px 4px 0px',
        },
        '& .ant-picker': {
            boxSizing: 'border-box',
            height: 32,
            border: '1px solid #999999',
            borderRadius: 4,
            marginRight: 2,
        },
        '& .ant-form-item-control-input-content': {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    box: {
        ...commonStyleSheet(theme).flexRow,
        padding: 20,
        alignItems: 'normal',
        boxSizing: 'border-box',
        borderRadius: 4,
        backgroundColor: '#FFFFFF',
    },
    boxS: {
        ...commonStyleSheet(theme).flexColumn,
        padding: 20,
        alignItems: 'normal',
        boxSizing: 'border-box',
        borderRadius: 4,
        backgroundColor: '#FFFFFF',
    },
    boxRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
    },
    singleBox: {
        marginLeft: 3,
        marginRight: 3,
    },
    addressBox: {
        width: '100%',
        margin: '0 20px',
        // maxHeight: `calc((100vh - ${NAVBAR_HEIGHT}px) - 300px)`,
    },
    field: {
        ...commonStyleSheet(theme).flexColumn,
        '& .ant-form-item': {
            marginBottom: 12,
        },
        width: '40%',
    },
    flexRow: {
        ...commonStyleSheet(theme).flexRow,
        justifyContent: 'space-between',
    },
    flexRowVerify: {
        ...commonStyleSheet(theme).flexRow,
        justifyContent: 'flex-start',
    },
    schedule: {
        ...commonStyleSheet(theme).flexRow,
    },
    scheduleBox: {
        ...commonStyleSheet(theme).flexColumn,
        width: '65%',
    },
    title: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '50%',
    },
    titleEway: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '160%',
    },
    dimentionTitle: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '35%',
    },
    referenceTitle: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
    },
    referenceItemEway: {
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 16,
    },
    serviceItem: {
        width: '100%',
        flexDirection: 'row',
        marginRight: 0,
        fontSize: 12,
    },
    headers: {
        display: 'flex',
        flexDirection: 'row',
    },
    FinalPrice: {
        width: '100%',
        flexDirection: 'row',
        marginRight: 0,
        fontSize: 12,
        display: 'flex',
        textAlign: 'left',
    },
    column: {
        display: 'flex',
        alignItems: 'center',
    },
    tat: {
        width: '100%',
        fontSize: 12,
    },
    codService: {
        display: 'flex',
        width: '15%',
        flexDirection: 'row',
        alignItems: 'center',
        fontSize: 10,
        fontWeight: 500,
        color: 'black',
        fontStyle: 'italic',
    },
    serviceableTitle: {
        marginRight: 8,
    },
    descriptionTitle: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '25%',
    },
    vehicleCategoryTitle: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
    },
    input: {
        width: 250,
        '& .ant-select-selection--single': {
            width: 250,
        },
        minWidth: 250,
        maxWidth: 250,
    },
    formItem: {
        marginBottom: 10,
        width: '45%',
        ...commonStyleSheet(theme).flexRow,
        '& .ant-form-item-label': {
            lineHeight: '14px',
            textAlign: 'left',
            width: '30%',
        },
        '& .ant-form-item': {
            marginBottom: 0,
        },
        '& .ant-form-explain': {
            fontSize: 10,
        },
    },
    msg: {
        marginLeft: '16%',
        color: '#fc036b',
        fontSize: 14,
        fontWeight: 'bold',
    },
    ellipses: {
        fontSize: 12,
        maxWidth: '94%',
        display: 'flex',
        wordWrap: 'break-word',
        whiteSpace: 'initial',
        margin: 0,
        padding: 0,
    },
    labelEllipsis: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    textValue: {
        padding: '0 0 12px 8px',
    },
    required: {
        color: '#EB2727',
        fontSize: 12,
    },
    boxTitle: {
        ...commonStyleSheet(theme).flexColumn,
        fontSize: 16,
        color: '#333333',
        fontWeight: 'bold',
        width: '15%',
    },
    boxFields: {
        width: '100%',
    },
    boxIcon: {
        width: '100%',
        height: 30,
        fontSize: 16,
        color: '#333333',
        textAlign: 'left',
    },
    closeIcon: {
        marginRight: 8,
        cursor: 'pointer',
        color: '#000000',
        letterSpacing: 0,
        fontFamily: 'Open Sans',
        fontWeight: 600,
        fontSize: 16,
    },
});

export const singleConsignmentStyles = (theme: ThemeType) => ({
    ...commonStyles(theme),
    main: {
        fontFamily: 'Open Sans',
        letterSpacing: 0,
        '& .ant-drawer-header': {
            padding: 12,
        },
        '& .ant-drawer-body': {
            padding: 0,
            backgroundColor: '#FFF',
        },
        '& .ant-btn-primary': {
            borderRadius: 4,
        },
        // marginBottom: 100,
    },
    newButton: {
        height: 32,
        borderRadius: '4px',
        fontSize: 12,
        backgroundColor: `${theme.primaryColor} !important`,
        border: `1px solid ${theme.primaryColor}`,
        color: '#FFFFFF',
        fontFamily: 'Open Sans',
        fontWeight: 600,
        letterSpacing: 0,
        marginLeft: '16px',
    },
    modal: {
        '& .ant-modal-header': {
            backgroundColor: theme.primaryColor,
            borderRadius: '4px 4px 0 0',
            padding: '16px 24px',
            marginBottom: '16px',
        },
        '& .ant-modal-title': {
            color: '#FFFFFF',
            fontWeight: 600,
            fontSize: '16px',
        },
        '& .ant-modal-close-x': {
            color: '#FFFFFF',
        },
        '& .ant-modal-content': {
            borderRadius: 4,
        },
        '& .ant-modal-body': {
            padding: 24,
            marginTop: -30,
        },
        '& .ant-btn-primary': {
            fontSize: 12,
            fontWeight: 600,
        },
        '& span': {
            fontSize: '14px',
            fontWeight: 600,
        },
    },
    newButtonDisabled: {
        height: 32,
        borderRadius: '4px',
        fontSize: 12,
        backgroundColor: '#EDEDED !important',
        border: '1px solid #EDEDED',
        color: '#FFFFFF',
        fontFamily: 'Open Sans',
        fontWeight: 600,
        letterSpacing: 0,
    },
    nextPreButton: {
        color: theme.primaryColor,
        fontWeight: 'bold',
    },
    header: {
        ...commonStyleSheet(theme).flexRow,
        fontWeight: 600,
        fontSize: 20,
        color: '#000000',
        width: '100%',
        maxHeight: 57,
        justifyContent: 'space-between',
        padding: 0,
        fontFamily: 'Open Sans',
        letterSpacing: 0,
    },
    booking: {
        ...commonStyleSheet(theme).flexRow,
        backgroundColor: '#FFF',
        alignItems: 'center',
        padding: '24px 20px',
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 600,
        boxSizing: 'border-box',
        boxShadow: '2px 2px 6px 0 rgba(0,0,0,0.05)',
    },
    pageSelector: {
        ...commonStyleSheet(theme).flexRow,
        boxSizing: 'border-box',
        border: '1px solid #FAF6EF',
        borderRadius: 4,
        backgroundColor: '#FFF8E7',
        boxShadow: '2px 2px 6px 0 rgba(0,0,0,0.05)',
    },
    tabSelector: {
        padding: 20,
        ...commonStyleSheet(theme).flexRow,
        alignItems: 'center',
        width: '19.5%',
        cursor: 'pointer',
    },
    filledCircle: {
        height: 20,
        width: 20,
        borderRadius: 10,
        border: '2px solid #FFF',
    },
    tabName: {
        marginLeft: 8,
        color: '#111111',
        fontSize: 14,
    },
    tabDescription: {
        fontSize: 10,
        marginLeft: 8,
    },
    tabIcon: {
        height: 24,
        width: 24,
        borderRadius: 12,
    },
    footer: {
        ...commonStyleSheet(theme).flexRow,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        bottom: 0,
        color: '#FFFFFF',
        width: '100%',
        height: 65,
        padding: '0 20px',
        fontSize: 14,
        backgroundColor: 'white',
        opacity: 1,
        zIndex: 5,
    },
    boxFieldsCol: {
        width: '80%',
        ...commonStyleSheet(theme).flexColumn,
    },
});

export const stepOneStyles = (theme: ThemeType) => ({
    ...commonStyles(theme),
    boxTitle: {
        ...commonStyleSheet(theme).flexColumn,
        fontSize: 16,
        color: '#333333',
        fontWeight: 'bold',
        width: '15%',
    },
    boxFields: {
        width: '100%',
        display: 'flex',
    },
    boxFieldsRadio: {
        width: '100%',
        display: 'flex',
        '& .ant-radio-wrapper': {
            display: 'inline',
            color: '#111111',
            fontFamily: 'Open Sans',
            fontSize: 14,
            fontWeight: 600,
            letterSpacing: 0,
            // margin: '4px 24px 4px 0px',
        },
    },
    boxFieldsCol: {
        width: '80%',
        ...commonStyleSheet(theme).flexColumn,
    },
    boxIcon: {
        width: '100%',
        height: 30,
        fontSize: 16,
        color: '#333333',
        textAlign: 'left',
    },
    box: {
        ...commonStyleSheet(theme).flexRow,
        padding: 20,
        alignItems: 'normal',
        boxSizing: 'border-box',
        borderRadius: 4,
        backgroundColor: '#FFFFFF',
        width: '100%',
    },
    reference: {
        ...commonStyleSheet(theme).flexColumn,
        alignContent: 'flex-start',
        width: '100%',
    },
    weight: {
        width: '32%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 16,
        marginRight: 100,
        '& .ant-input-group > .ant-input:first-child': {
            width: 150,
            marginRight: 0,
        },
        '& .ant-select-selection-item, .ant-select-single.ant-select-show-arrow': {
            color: '#111111',
            fontSize: 12,
            fontWeight: 600,
        },
    },
    formItem: {
        width: '45%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        marginBottom: 16,
        '& .ant-checkbox + span': {
            color: '#111111',
            fontSize: 14,
            fontWeight: 600,
        },
    },
    formItemWeight: {
        width: '45%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        marginBottom: 16,
        '& .ant-input-wrapper': {
            width: 250,
        },
    },
    referenceItem: {
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 16,
    },
    dimensions: {
        ...commonStyleSheet(theme).flexColumn,
        width: 250,
        alignItems: 'left',
        marginBottom: 16,
    },
    description: {
        ...commonStyleSheet(theme).flexColumn,
        width: '80%',
    },
    contentType: {
        width: 250,
        ...commonStyles(theme).flexRow,
    },
    deliveryType: {
        width: '25%',
        ...commonStyleSheet(theme).flexColumn,
    },
});

export const itemDetailsStyles = (theme: ThemeType) => ({
    ...commonStyles(theme),
    main: {
        ...commonStyles(theme).main,
        paddingBottom: 0,
        maxHeight: undefined,
        minHeight: undefined,
        fontFamily: 'Open Sans',
        letterSpacing: 0,
        '& .ant-drawer-header': {
            padding: 12,
        },
        '& .ant-drawer-body': {
            padding: 0,
            backgroundColor: '#FFF',
        },
        '& .ant-btn-primary': {
            borderRadius: 4,
        },
        marginBottom: 100,
    },
    header: {
        ...commonStyleSheet(theme).flexRow,
        fontWeight: 600,
        fontSize: 20,
        color: '#000000',
        width: '100%',
        justifyContent: 'space-between',
        padding: 0,
        fontFamily: 'Open Sans',
        letterSpacing: 0,
    },
    footer: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        fontWeight: 600,
        fontSize: 20,
        color: '#000000',
        width: 'auto',
        justifyContent: 'flex-end',
        padding: '10px',
        fontFamily: 'Open Sans',
        letterSpacing: 0,
    },
    title: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        // width: '100%',
        marginBottom: 4,
        marginLeft: 4,
    },
    formItemContainer: {
        borderStyle: 'hidden hidden solid double',
        borderColor: '#eeeded',
        boxShadow: '5px 5px #eeeded',
    },
});

export const stepTwoStyles = (theme: ThemeType) => ({
    ...commonStyles(theme),
    main: {
        ...commonStyles(theme).main,
        maxHeight: undefined,
        minHeight: undefined,
    },
    searchAddress: {
        ...commonStyleSheet(theme).flexRow,
        justifyContent: 'flex-end',
        margin: 0,
    },
    consigneeDetailsInput: {
        ...commonStyleSheet(theme).flexRow,
        justifyContent: 'space-between',
        margin: 0,
        '& .ant-radio-wrapper': {
            display: 'inline',
            color: '#111111',
            fontFamily: 'Open Sans',
            fontSize: 14,
            fontWeight: 600,
            letterSpacing: 0,
            height: 32,
        },
    },
    searchText: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
    },
    addressList: {
        ...commonStyleSheet(theme).flexColumn,
        // marginBottom: 12,
        // maxHeight: 500,
        overflow: 'auto',
    },
    addressLine: {
        display: 'flex',
        flexDirection: 'column',
        padding: 12,
        width: '98%',
        borderRadius: 4,
        backgroundColor: '#FCFCFC',
        margin: '4px 0px',
        cursor: 'pointer',
    },
    addressLineSelected: {
        backgroundColor: '#E7F5FF',
    },
    addressDetails: {
        ...commonStyleSheet(theme).flexRow,
        // justifyContent: 'space-between',
        alignItems: 'top',
        width: '100%',
        marginTop: 16,
    },
    consigneeDetails: {
        alignItems: 'top',
        width: '100%',
        margin: '16px 0 0 16px',
    },
    addressLineDetails: {
        ...commonStyleSheet(theme).flexRow,
        justifyContent: 'space-between',
        alignItems: 'top',
        width: '100%',
        padding: '0px 10px 0px 25px',
    },
    addressMenu: {
        ...commonStyleSheet(theme).flexColumn,
        marginTop: 16,
        minWidth: 188,
        boxSizing: 'border-box',
        width: 1,
        borderRight: '1px solid #EDEDED',
        fontFamily: 'Open Sans',
        fontSize: 14,
        letterSpacing: 0,
        main: {
            '& .ant-radio-wrapper': {
                backgroundColor: 'blue',
            },
        },
    },
    addressItem: {
        ...commonStyleSheet(theme).flexRow,
        height: 42,
        width: 188,
        alignItems: 'center',
        cursor: 'pointer',
    },
    addressItemSelected: {
        ...commonStyleSheet(theme).flexRow,
        height: 42,
        width: 188,
        alignItems: 'center',
        cursor: 'pointer',
        fontWeight: 'bold',
        borderRight: `4px solid ${theme.secondryColor}`,
    },
    tabIcon: {
        height: 24,
        width: 24,
        borderRadius: 12,
        margin: '0px 10px',
    },
    filledCircle: {
        height: 20,
        width: 20,
        borderRadius: 10,
        border: '2px solid #FFF',
    },
});

export const consignorDetailsStyles = (theme: ThemeType) => ({
    ...stepTwoStyles(theme),
    main: {
        ...commonStyles(theme).main,
        paddingBottom: 0,
        maxHeight: undefined,
        minHeight: undefined,
        fontFamily: 'Open Sans',
        letterSpacing: 0,
        '& .ant-drawer-header': {
            padding: 12,
        },
        '& .ant-drawer-body': {
            padding: 0,
            backgroundColor: '#FFF',
        },
        '& .ant-btn-primary': {
            borderRadius: 4,
        },
        '& .ant-radio-wrapper': {
            display: 'inline',
            color: '#111111',
            fontFamily: 'Open Sans',
            fontSize: 14,
            fontWeight: 600,
            letterSpacing: 0,
            height: 32,
        },
        // '&.ant-upload-list'.ant-upload-list-text
        marginBottom: 50,
    },
    header: {
        ...commonStyleSheet(theme).flexRow,
        fontWeight: 600,
        fontSize: 20,
        color: '#000000',
        width: '100%',
        justifyContent: 'space-between',
        padding: 0,
        fontFamily: 'Open Sans',
        letterSpacing: 0,
    },
    title: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
        marginLeft: 4,
    },
});

export const sellerDetailsStyles = (theme: ThemeType) => ({
    ...stepTwoStyles(theme),
    iframeModal: {
        width: 630,
        height: 500,
        '& .ant-modal-header': {
            borderBottom: 'none',
        },
    },
    main: {
        ...commonStyles(theme).main,
        paddingBottom: 0,
        maxHeight: undefined,
        minHeight: undefined,
        fontFamily: 'Open Sans',
        letterSpacing: 0,
        '& .ant-drawer-header': {
            padding: 12,
        },
        '& .ant-drawer-body': {
            padding: 0,
            backgroundColor: '#FFF',
        },
        '& .ant-btn-primary': {
            borderRadius: 4,
        },
        '& .ant-radio-wrapper': {
            display: 'inline',
            color: '#111111',
            fontFamily: 'Open Sans',
            fontSize: 14,
            fontWeight: 600,
            letterSpacing: 0,
            height: 32,
        },
        // '&.ant-upload-list'.ant-upload-list-text
        marginBottom: 50,
    },
    header: {
        ...commonStyleSheet(theme).flexRow,
        fontWeight: 600,
        fontSize: 20,
        color: '#000000',
        width: '100%',
        justifyContent: 'space-between',
        padding: 0,
        fontFamily: 'Open Sans',
        letterSpacing: 0,
    },
    title: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
        marginLeft: 4,
    },
    sellerDetails: {
        ...commonStyleSheet(theme).flexRow,
        alignItems: 'top',
        width: '100%',
        marginTop: 16,
        margin: 20,
    },
    detailsGroup: {
        ...commonStyleSheet(theme).flexColumn,
        width: '24%',
        minWidth: 150,
    },
    detailsRow: {
        ...commonStyleSheet(theme).flexRow,
        width: '80%',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        margin: 10,
    },
    detailsRowAdd: {
        ...commonStyleSheet(theme).flexRow,
        width: '80%',
        flexWrap: 'wrap',
        alignItems: 'top',
        justifyContent: 'space-between',
        margin: '0 10px 10px 10px',
    },
    detailsRowKYC: {
        ...commonStyleSheet(theme).flexRow,
        width: '100%',
        flexWrap: 'wrap',
        margin: '10px 0',
        alignItems: 'top',
        justifyContent: 'space-between',
    },
    flexRow: {
        ...commonStyleSheet(theme).flexRow,
        flexWrap: 'wrap',
    },
    addressDetails: {
        ...commonStyleSheet(theme).flexRow,
        alignItems: 'top',
        width: '100%',
        margin: 10,
    },
    rowTitle: {
        width: '15%',
        color: '#777777',
        fontWeight: 600,
    },
    referenceTitle: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        fontWeight: 400,
        marginBottom: 4,
    },
    textValue: {
        padding: 0,
        color: '#111111',
        fontWeight: 600,
        fontSize: 12,
        '& .ant-btn-link': {
            padding: 0,
            margin: 0,
            display: 'flex',
            alignItems: 'top',
        },
    },
    previewButton: {
        padding: 0,
        margin: 0,
        fontWeight: 600,
        fontSize: 12,
    },
});

export const stepFourStyles = (theme: ThemeType) => ({
    ...commonStyles(theme),
    main: {
        ...commonStyles(theme).main,
        '& .ant-radio-wrapper': {
            display: 'inline',
            color: '#111111',
            fontFamily: 'Open Sans',
            fontSize: 14,
            fontWeight: 600,
            letterSpacing: 0,
        },
    },
    fields: {
        width: '100%',
        marginTop: 20,
        ...commonStyles(theme).flexRow,
        justifyContent: 'flex-start',
    },
    input: {
        marginRight: 12,
    },
    amount: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: 20,
        '& .ant-input-group > .ant-input:first-child': {
            width: 150,
            marginRight: 0,
        },
        '& .ant-select-selection-item, .ant-select-single.ant-select-show-arrow': {
            color: '#111111',
            fontSize: 12,
            width: 65,
            fontWeight: 600,
        },
    },
    inFavour: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: 20,
    },
    label: {
        color: '#333333',
        fontSize: 12,
    },
    formItem: {
        width: '100%',
    },
    contentType: {
        width: '150%',
        ...commonStyles(theme).flexRow,
    },
    subclass: {
        margin: '5%',
        display: 'flex',
        textAlign: 'center',
    },
    classMargin: {
        marginLeft: 4,
        marginRight: 4,
    },
    boxDummy: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        width: 600,
    },
});

export const scheduleStepStyles = (theme: ThemeType) => ({
    ...commonStyles(theme),
    boxFields: {
        width: '100%',
        '& .ant-checkbox-wrapper + .ant-checkbox-wrapper': {
            margin: 0,
            marginTop: 6,
        },
    },
    checkbox: {
        display: 'flex',
        width: '90%',
        color: '#111111',
        fontSize: 14,
        fontWeight: 600,
        letterSpacing: 0,
        padding: 12,
        borderRadius: 4,
        backgroundColor: '#FCFCFC',
    },
    checkboxBody: {
        '& .ant-checkbox + span': {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
    },
    checkboxSelected: {
        backgroundColor: '#E7F5FF',
    },
});
