export const Routes = {
    DASHBOARD: 'dashboard',
    CONSIGNMENTS: 'consignments',
    PLUGIN_ORDERS: 'plugin-orders',
    PICKUPS: 'pickups',
    ADDRESS_BOOK: 'address-book',
    WEIGHT_MISMTACH: 'weight-mismatch',
    VIRTUAL_INVENTORY: 'virtual-inventory',
    DOWNLOADS: 'downloads',
    WALLET: 'wallet',
    WALLET_LEDGER: 'wallet/ledger',
    BLOCKED_LEDGER: 'wallet/blocked/ledger',
    WALLET_WEIGHT_MISMATCH: 'wallet/weight-mismatch',
    INVOICE: 'invoice',
    HELP: 'help',
    PLUGIN: 'plugin',
    TUTORIAL: 'tutorial',
    FORGOT: 'forgot-password',
    LOGIN: 'login',
    SUPER_LOGIN: 'superuser/login',
    CUSTOMER_ACTIVATION: 'customerActivation',
    RESET_PASSWORD: 'resetPassword',
    EXCEL_UPLOAD: 'consignments/excel-upload',
    PRINT_HISTORY: 'consignments/print-history',
    CONSIGNMENT_DETAILS: 'consignments/details',
    SETTINGS: 'settings',
    SETTINGS_HEADER: 'settings/header-mapping',
    SETTINGS_PIECE_HEADER: 'settings/piece-header-mapping',
    SETTINGS_PRODUCT: 'settings/product-mapping',
    SETTINGS_CHANNEL: 'settings/channel-integrations',
    SETTINGS_ACCOUNT: 'settings/account',
    ASN: 'asn',
    ASN_EXCEL_UPLOAD: 'asn/excel-upload',
    ANALYTICS: 'analytics',
    API_PLAYGROUND: 'api-playground',
    RTO_DASHBOARD: 'rto-dashboard',
    ANALYTICS_DASHBOARD: 'analytics/dashboard',
    REMITTANCE: 'remittance',
    INVOICE_REPORT: 'reports/invoice',
    REPORT: 'reports',
    REMITTANCE_REPORT: 'reports/remittance',
    ADDITIONAL_SERVICES: 'additional-services',
    SSO_LOGIN_REQUEST: 'sso-login',
    RATE_CALCULATOR: 'rate-calculator',
    PINCODE_SERVICEABILITY: 'pincode-serviceability',
    USER_MANAGEMENT: 'user-management',
    CONSIGNOR_MANAGEMENT: 'consignor-management',
    SELLER_DETAILS: 'consignor-management/details',
};

interface RouteRawDetails {
    title: string;
    isVisible: boolean | ((master: any) => boolean);
    isProtected: boolean;
}

export interface RouteDetails {
    title: string;
    isVisible: boolean;
    isProtected: boolean;
}

export const RoutesInfo: Record<keyof typeof Routes, RouteRawDetails | undefined> = {
    // undefined means the route is not available, reason for that
    // - It was previously available but now removed, as per new UI design
    DASHBOARD: {
        title: 'Overview',
        isProtected: true,
        isVisible: (master: any) => {
            if (master?.properties && master?.parts_to_show?.enable_customer_config) {
                if (master?.Customer?.is_international_customer) {
                    return false;
                }
                return master?.properties?.Analytics;
            }
            return true;
        },
    },
    CONSIGNMENTS: {
        title: 'Consignments',
        isProtected: true,
        isVisible: (master: any) => {
            return master?.customerUserPermissions?.consignment_ops;
        },
    },
    PLUGIN_ORDERS: {
        title: 'Plugin Orders',
        isProtected: true,
        isVisible: (master: any) => {
            return master?.customerUserPermissions?.pickup_ops;
        },
    },
    PICKUPS: {
        title: 'Pickups',
        isProtected: true,
        isVisible: () => {
            return true;
        },
    },
    ADDRESS_BOOK: {
        title: 'Address Book',
        isProtected: true,
        isVisible: (master: any) => {
            if (master?.properties && master?.parts_to_show?.enable_customer_config) {
                return master?.properties?.address_book && master?.customerUserPermissions?.address_book_update;
            }
            return true;
        },
    },
    WEIGHT_MISMTACH: {
        title: 'Weight Mismatch',
        isProtected: true,
        isVisible: (master: any) => {
            if (master?.properties && master?.parts_to_show?.enable_customer_config) {
                return master?.properties?.weight_mismatch && master?.customerUserPermissions?.wallet_view;
            }
            return true;
        },
    },
    VIRTUAL_INVENTORY: {
        title: 'Virtual Inventory',
        isProtected: true,
        isVisible: (master: any) => {
            if (master?.properties && master?.parts_to_show?.enable_customer_config) {
                return master?.parts_to_show?.virtual_series && master?.properties?.virtual_inventory
                    && master?.customerUserPermissions?.virtual_inventory;
            }
            return master?.parts_to_show?.virtual_series;
        },
    },
    DOWNLOADS: {
        title: 'Downloads',
        isProtected: true,
        isVisible: true,
    },
    WALLET: undefined,
    WALLET_LEDGER: {
        title: 'Wallet Ledger',
        isProtected: true,
        isVisible: (master: any) => {
            let showWalletSectionCustomerProfile = true;
            if (master?.properties && master?.parts_to_show?.enable_customer_config) {
                showWalletSectionCustomerProfile = master?.properties?.Wallet;
            }
            if (master?.Customer?.cnc_flag && showWalletSectionCustomerProfile) {
                return true;
            }
            return false;
        },
    },
    BLOCKED_LEDGER: {
        title: 'Blocked Amount Ledger',
        isProtected: true,
        isVisible: (master: any) => {
            let showWalletSectionCustomerProfile = true;
            if (master?.properties && master?.parts_to_show?.enable_customer_config) {
                showWalletSectionCustomerProfile = master?.properties?.Wallet;
            }
            if (master?.Customer?.cnc_flag && showWalletSectionCustomerProfile) {
                return true;
            }
            return false;
        },
    },
    WALLET_WEIGHT_MISMATCH: {
        title: 'Weight Mismatch',
        isProtected: true,
        isVisible: (master: any) => {
            let showWalletSectionCustomerProfile = true;
            if (master?.properties && master?.parts_to_show?.enable_customer_config) {
                showWalletSectionCustomerProfile = master?.properties?.weight_mismatch;
            }
            if (master?.Customer?.cnc_flag && showWalletSectionCustomerProfile) {
                return true;
            }
            return false;
        },
    },
    INVOICE: undefined,
    HELP: undefined,
    PLUGIN: undefined,
    TUTORIAL: undefined,
    FORGOT: undefined,
    LOGIN: undefined,
    SUPER_LOGIN: undefined,
    CUSTOMER_ACTIVATION: undefined,
    RESET_PASSWORD: undefined,
    EXCEL_UPLOAD: {
        title: 'Excel Uploaded',
        isProtected: true,
        isVisible: (master: any) => {
            if (master?.properties && master?.parts_to_show?.enable_customer_config) {
                return master?.properties?.cn_multiple_creation;
            }
            return true;
        },
    },
    PRINT_HISTORY: {
        title: 'Print History',
        isProtected: true,
        isVisible: (master: any) => {
            if (master?.properties && master?.parts_to_show?.enable_customer_config) {
                return master?.properties?.cn_multiple_creation;
            }
            return true;
        },
    },
    CONSIGNMENT_DETAILS: {
        title: 'Consignment Details',
        isProtected: true,
        isVisible: true,
    },
    SETTINGS: undefined,
    SETTINGS_HEADER: {
        title: 'Header Mapping',
        isProtected: true,
        isVisible: (master: any) => {
            // to honor backward compatibility, as this was in use to hide header mapping from setting
            if (master?.config?.customer_portal_config?.show_header_mapping === false
                && !master?.config?.customer_portal_ops_config?.additional_config?.config_value?.allow_header_mapping) {
                return false;
            }

            // To hide the header mapping using the configuration
            if (master?.properties && master?.parts_to_show?.enable_customer_config) {
                return master?.properties?.header_mapping_settings && master?.customerUserPermissions?.setting;
            }
            return true;
        },
    },
    SETTINGS_PIECE_HEADER: {
        title: 'Piece Header Mapping',
        isProtected: true,
        isVisible: (master: any) => {
            // to honor backward compatibility, as this was in use to hide header mapping from setting
            if (master?.config?.customer_portal_config?.show_header_mapping === false
                && !master?.config?.customer_portal_ops_config?.additional_config?.config_value?.allow_header_mapping) {
                return false;
            }

            // To hide the header mapping using the configuration
            if (master?.properties && master?.parts_to_show?.enable_customer_config) {
                return master?.properties?.header_mapping_settings && master?.customerUserPermissions?.setting;
            }
            return true;
        },
    },
    SETTINGS_PRODUCT: {
        title: 'Product Code Mapping',
        isProtected: true,
        isVisible: (master: any) => {
            // to honor backward compatibility, as this was in use to hide header mapping from setting
            if (master?.config?.customer_portal_config?.show_product_code_mapping === false) {
                return false;
            }

            // To hide the header mapping using the configuration
            if (master?.properties && master?.parts_to_show?.enable_customer_config) {
                return master?.properties?.product_code_mapping_settings && master?.customerUserPermissions?.setting;
            }
            return true;
        },
    },
    SETTINGS_CHANNEL: {
        title: 'Channel Integrations',
        isProtected: true,
        isVisible: (master: any) => {
            if (master?.parts_to_show?.enable_shopify_integration) {
                return true;
            }
            if (master?.properties && master?.parts_to_show?.enable_customer_config) {
                return master?.properties?.plugin_orders;
            }
            return true;
        },
    },
    SETTINGS_ACCOUNT: {
        title: 'Account Settings',
        isProtected: true,
        isVisible: (master: any) => {
            if (master?.properties && master?.parts_to_show?.enable_customer_config) {
                return master?.properties?.account_settings && master?.customerUserPermissions?.setting;
            }
            return true;
        },
    },
    ASN: {
        title: 'ASN',
        isProtected: true,
        isVisible: (master: any) => master?.parts_to_show?.enable_advance_shipping_note_feature || false,
    },
    ASN_EXCEL_UPLOAD: {
        title: 'ASN Excel Upload',
        isProtected: true,
        isVisible: (master: any) => master?.parts_to_show?.enable_advance_shipping_note_feature || false,
    },
    ANALYTICS: {
        title: 'Overview',
        isProtected: true,
        isVisible: (master: any) => {
            if (master?.properties && master?.parts_to_show?.enable_customer_config) {
                if (master?.Customer?.is_international_customer) {
                    return false;
                }
                return master?.properties?.Analytics;
            }
            return true;
        },
    },
    API_PLAYGROUND: {
        title: 'API Playground',
        isProtected: true,
        isVisible: (master: any) => {
            if (master?.properties && master?.parts_to_show?.enable_customer_config) {
                return master?.properties?.api_playground && master?.customerUserPermissions?.api_playground;
            }
            return true;
        },
    },
    RTO_DASHBOARD: {
        title: 'RTO Dashboard',
        isProtected: true,
        isVisible: (master: any) => {
            const flag = master?.config?.customer_portal_config?.show_rto_dashboard;
            if (master?.properties && master?.parts_to_show?.enable_customer_config) {
                return flag && master?.properties?.rto_dashboard;
            }
            return flag;
        },
    },
    ANALYTICS_DASHBOARD: {
        title: 'Dashboard',
        isProtected: true,
        isVisible: (master: any) => {
            if (master?.properties && master?.parts_to_show?.enable_customer_config) {
                return master?.Customer?.is_international_customer ? false : master?.properties?.Analytics;
            }
            return true;
        },
    },
    REMITTANCE: undefined,
    INVOICE_REPORT: {
        title: 'Invoice Report',
        isProtected: true,
        isVisible: (master: any) => {
            // Should honor existing check in the Reports tab
            if (master?.Customer?.is_cpdp) {
                return false;
            }

            // to hide using the customer profile configuration
            if (master?.properties && master?.parts_to_show?.enable_customer_config) {
                return master?.properties?.Invoices && master?.customerUserPermissions?.financials_view;
            }
            return true;
        },
    },
    REPORT: undefined,
    REMITTANCE_REPORT: {
        title: 'COD Remittance',
        isProtected: true,
        isVisible: (master: any) => {
            // Should honor existing check in the Reports tab
            if (master?.Customer?.consignee_type !== 'B2C') {
                return false;
            }

            // to hide using the customer profile configuration
            if (master?.properties && master?.parts_to_show?.enable_customer_config) {
                return master?.properties?.cod_remittance && master?.customerUserPermissions?.financials_view;
            }
            return true;
        },
    },
    ADDITIONAL_SERVICES: {
        title: 'Additional Services',
        isProtected: true,
        isVisible: (master: any) => master?.config?.customer_portal_config?.enable_supplementary_services_module,
    },
    SSO_LOGIN_REQUEST: undefined,
    CONSIGNOR_MANAGEMENT: {
        title: 'Consignor Admin',
        isProtected: true,
        isVisible: (master: any) => master?.Customer?.is_international_customer || false,
    },
    SELLER_DETAILS: {
        title: 'Consignor Details',
        isProtected: true,
        isVisible: (master: any) => master?.Customer?.is_international_customer || false,
    },
    RATE_CALCULATOR: {
        title: 'Rate Calculator',
        isProtected: true,
        isVisible: (master: any) => {
            return master?.config?.customer_portal_config?.show_rate_calculator;
        },
    },
    PINCODE_SERVICEABILITY: {
        title: 'Pincode Serviceability',
        isProtected: true,
        isVisible: (master: any) => {
            let flag = true;
            if (master?.properties && master?.parts_to_show?.enable_customer_config) {
                flag = master?.properties?.pincode_serviceability;
            }
            return flag && !master?.config?.customer_portal_consignment_config?.is_international_client;
        },
    },
    USER_MANAGEMENT: {
        title: 'User Management',
        isProtected: true,
        isVisible: (master: any) => {
            if (master?.properties && master?.parts_to_show?.enable_customer_config) {
                return master?.CustomerUserDetails?.enable_customer_user && master?.customerUserPermissions?.user_management;
            }
            return true;
        },
    },
};

export const formRules = {
    required: {
        required: true,
        message: 'Required',
    },
    pattern: {
        pattern: /^[^\s]+$/,
        message: 'No whitespace allowed',
    },
    number: {
        pattern: /^[0-9]*$/,
        message: 'Only Integer allowed',
    },
    max: {
        max: 50,
        message: 'Max length allowed is 50',
    },
    maxNumber1000: {
        pattern: /^(?:[1-9]\d{0,2}|1000)$/,
        message: 'Max number allowed is 1000',
    },
    greaterThanZero: {
        pattern: /^(0?\.[1-9]\d*|[1-9]\d*(\.\d+)?)$/,
        message: 'Value should be greater than 0',
    },
};

export const languageCodeMapping: any = {
    en: {
        prettyName: 'English',
    },
    hi: {
        prettyName: 'Hindi',
    },
    ba: {
        prettyName: 'Bahasa',
    },
    ar: {
        prettyName: 'Arabic',
    },
    hb: {
        prettyName: 'Hebrew',
    },
    us: {
        prettyName: 'English(US)',
    },
    ger: {
        prettyName: 'German',
    },
    'en-EU': {
        prettyName: 'English-EU',
    },
};

export enum ZIndex {
    // Z-index for application
    // Prefer this over using hardcoded z-index values
    // Strictly avoid using z-index equal to 1000 or above, as it may conflict with antd components
    iframe = 500,
    navbar = 502,
    sideMenu = 501,
    loader = 10,
}

export const SEARCH_TIMEOUT = 500;
