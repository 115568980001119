import { ProductInput } from './settings.types';

export const accountFields: Record<string, ProductInput> = {
    username: {
        key: 'username',
        pretty_name: 'Username',
        placeholder: 'Username',
        required: true,
        errormsg: 'Enter Username.',
        disabled: true,
    },
    name: {
        key: 'name',
        pretty_name: 'Name',
        placeholder: 'Name',
        required: true,
        errormsg: 'Enter Name.',
        disabled: true,
    },
    phone_list: {
        key: 'phone_list',
        pretty_name: 'Phone',
        placeholder: 'Phone',
        required: true,
        errormsg: 'Enter Phone.',
    },
    status: {
        key: 'status',
        pretty_name: 'Status',
        placeholder: 'Status',
        required: true,
        errormsg: 'Select Status.',
    },
    email_list: {
        key: 'email_list',
        pretty_name: 'Email',
        placeholder: 'Email',
        required: true,
        errormsg: 'Enter Email.',
    },
    password: {
        key: 'password',
        pretty_name: 'Password',
        placeholder: 'Password',
        required: true,
        errormsg: 'Enter Password.',
    },
    comPassword: {
        key: 'comPassword',
        pretty_name: 'Confirm Password',
        placeholder: 'Confirm Password',
        required: true,
        errormsg: 'Enter Confirm Password.',
    },
    account_number: {
        key: 'account_number',
        pretty_name: 'Bank Account No.',
        placeholder: 'Bank Account No.',
        required: false,
    },
    bank_code: {
        key: 'bank_code',
        pretty_name: 'Bank AD Code',
        placeholder: 'Bank AD Code',
        required: false,
    },
    branch_code: {
        key: 'branch_code',
        pretty_name: 'Bank IFSC Code',
        placeholder: 'Bank IFSC Code',
        required: false,
    },
    bank_name: {
        key: 'bank_name',
        pretty_name: 'Bank Name',
        placeholder: 'Bank Name',
        required: false,
    },
    kyc_doc_type: {
        key: 'kyc_doc_type',
        pretty_name: 'KYC Doc Type',
        placeholder: 'KYC Doc Type',
        required: false,
        options: [
            {
                label: 'AADHAAR CARD',
                value: 'AADHAAR CARD',
            },
            {
                label: 'GSTIN',
                value: 'GSTIN',
            },
            {
                label: 'PAN CARD',
                value: 'PAN CARD',
            },
            {
                label: 'PASSPORT',
                value: 'PASSPORT',
            },
            {
                label: 'VOTER ID',
                value: 'VOTER ID',
            },
        ],
    },
    customer_gstin_number: {
        key: 'customer_gstin_number',
        pretty_name: 'GSTIN No.',
        placeholder: 'GSTIN No.',
        required: false,
    },
    customer_iec_number: {
        key: 'customer_iec_number',
        pretty_name: 'IEC No.',
        placeholder: 'IEC No.',
        required: false,
    },
    customer_pan: {
        key: 'customer_pan',
        pretty_name: 'PAN No.',
        placeholder: 'PAN No.',
        required: false,
    },
    kyc_doc_number: {
        key: 'kyc_doc_number',
        pretty_name: 'KYC Doc No.',
        placeholder: 'KYC Doc No.',
        required: false,
    },
    kyc_doc: {
        key: 'kyc_doc',
        pretty_name: 'Attachments',
        placeholder: '-',
        required: false,
    },
};

export const addressFields: Record<string, ProductInput> = {
    consignorName: {
        key: 'consignorName',
        pretty_name: 'Name',
        placeholder: 'Name',
        required: true,
        errormsg: 'Required',
        disabled: false,
    },
    addressLine1: {
        key: 'addressLine1',
        pretty_name: 'Address Line 1',
        placeholder: 'Address Line 1',
        required: true,
        errormsg: 'Required',
        disabled: false,
    },
    addressLine2: {
        key: 'addressLine2',
        pretty_name: 'Address Line 2',
        placeholder: 'Address Line 2',
        required: false,
        errormsg: 'Required',
        disabled: false,
    },
    city: {
        key: 'city',
        pretty_name: 'City',
        placeholder: 'City',
        required: true,
        errormsg: 'Required',
        disabled: false,
    },
    state: {
        key: 'state',
        pretty_name: 'State',
        placeholder: 'State',
        required: false,
        errormsg: 'Required',
        disabled: false,
    },
    country: {
        key: 'country',
        pretty_name: 'Country',
        placeholder: 'Country',
        defaultValue: 'India',
        required: true,
        disabled: true,
    },
    pincode: {
        key: 'pincode',
        pretty_name: 'Pincode',
        placeholder: 'Pincode',
        required: true,
        errormsg: 'Enter Pincode.',
        disabled: false,
    },
    phone: {
        key: 'phone',
        pretty_name: 'Phone',
        placeholder: 'Phone',
        required: true,
        errormsg: 'Enter Phone.',
        disabled: false,
    },
    consignorEmail: {
        key: 'consignorEmail',
        pretty_name: 'Email',
        placeholder: 'Email',
        required: true,
        errormsg: 'Enter Email.',
        disabled: false,
    },
};
