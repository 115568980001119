import {
    Breadcrumb,
    Button, Card, Dropdown, Form, Input, Menu, Modal, Select, Switch, Table, Tag, message,
} from 'antd';
import * as React from 'react';
import { StylesProps, ThemeType } from '../../theme/jss-types';
import { FormField, InputTypes } from 'components/create-consignment/create-consignment.types';
import TextArea from 'antd/lib/input/TextArea';
import { channelintegrationFormFields } from './settings.constants';
import { commonStyleSheet } from 'library/common-styles';
import { NAVBAR_HEIGHT } from 'library/globals';
import ShopifyIcon from 'assets/images/shopify.png';
import EcompluginIcon from 'assets/ecom-plugin-icon';
import { ReduxStore } from 'reducers/redux.types';
import { HocOptions } from 'components/common/generic-hoc.types';
import GenericHoc from 'components/common/generic-hoc';
import { Master } from 'types/master-data-types';
import HomeIcon from 'assets/home-icon';
import SettingIcon from 'assets/setting-icon';
import { createPlugin } from 'network/common.api';
import {
    FileTextOutlined,
    GlobalOutlined,
    InfoCircleFilled,
    LinkOutlined,
    MoreOutlined,
} from '@ant-design/icons';
import { IdName } from 'library/Types';
import { getPluginStoresList, getServiceList } from 'network/virtual-inventory';
import moment from 'moment';


const {
    storeUrl,
    appName,
    appId,
    appsecret,
    accesstoken,
    redirectionUrl,
    callbackUrl,
    webhookurl,
    defaultWeight,
    orderPullStep,
    defaultContentType,
    defaultLoadType,
    defaultDomesticServiceType,
    defaultLength,
    defaultHeight,
    defaultWidth,
    trackingUpdates,
    defaultVolumeUnit,
    defaultWeightUnit,
    autoShipment,
} = channelintegrationFormFields;

const styles = (theme: ThemeType) => ({
    main: {
        height: '100% - 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        backgroundColor: 'white',
        padding: '2px',
    },
    modal: {
        '& .ant-modal-header': {
            backgroundColor: '#082E78',
            borderRadius: '4px 4px 0 0',
        },
        '& .ant-modal-title, .ant-modal-close-x': {
            color: '#FFFFFF',
        },
        '& .ant-modal-content': {
            borderRadius: 4,
        },
        '& .ant-modal-body': {
            padding: '0px !important',
        },
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        // backgroundColor: 'rgba(0, 0, 0, 0.1)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    page: {
        padding: '12px',
    },

    block: {
        display: 'flex',
        alignItems: 'center',
        width: '60%',
        marginTop: '1.7%',

    },
    title: {
        padding: '0px',
        fontSize: '12px',
        fontFamily: 'Open Sans',
        fontWeight: 400,
        color: '#333',
        marginLeft: '4px',
        marginBottom: '16px',
        display: 'flex',
    },
    inputform: {
        marginLeft: '2px',
        width: '84%',
        height: '32px',
    },
    switchform: {
        height: '0px',
    },
    formitem: {
        width: '100%',
        padding: '0px',
        fontSize: '12px',
        fontFamily: 'Open Sans',
        fontWeight: 400,
        color: '#333',
        marginLeft: '4px',
        marginBottom: '10px',
        display: 'flex',
    },
    plugin: {
        fontSize: '14px',
        lineHeight: '14px',
        color: '#111',
        fontWeight: 700,
        fontFamily: 'Open Sans',
    },
    leftmenu: {
        width: '23%',
        borderRight: '1px solid #dddddd',
    },
    horizontalline: {
        height: '1px',
        backgroundColor: '#CCCCCC',
    },
    leftmenucontainer: {
        display: 'flex',
        gap: '20px',
        height: '100%',
        marginLeft: '10px',
    },
    sidemenu: {
        // width: '234px',
        width: '96%',
        flexShrink: '0',
        fontFamily: 'Open Sans',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '12px',
        '& .ant-modal-header': {
            backgroundColor: '#C9EEFF',
            borderRadius: '4px',
        },
        '& .ant-menu:not(.ant-menu-horizontal)': {
            backgroundColor: '#C9EEFF',
            borderRadius: '4px',
        },
    },
    plugindescription: {
        marginTop: '8px',
        marginRight: '2px',
        fontSize: '10px',
        lineHeight: '12px',
        color: '#666',
        fontWeight: 400,
        fontFamily: 'Open Sans',
    },
    plugintype: {
        marginTop: '8px',
        fontSize: '10px',
        lineHeight: '10px',
        color: '#111',
        fontWeight: 600,
        fontFamily: 'Open Sans',
        fontStyle: 'italic',
    },

    closebutton: {
        cursor: 'pointer',
        height: '24px',
        width: '24px',
        flexShrink: 0,
        marginTop: '5px',
        fontSize: '20px',
    },
    pluginname: {
        fontSize: '20px',
        fontWeight: 600,
        lineHeight: '20px',
        color: '#000',
        fontFamily: 'Open Sans',
        marginLeft: '4%',
        marginTop: '3px',
    },
    input: {
        margin: '2%',
        marginTop: '4%',
        padding: '4px',
        fontSize: '12px',
        fontWeight: 400,
    },
    inputfield: {
        width: '100%',
        padding: '8px',
        borderRadius: '4px',
        height: '32px',
        border: '1px solid #999',
    },
    popupbox: {
        backgroundColor: 'white',
        padding: '0px',
        borderRadius: '4px',
        width: '466px',
        height: '191px',
    },
    popupsubmit: {
        height: '32px',
        width: '74px',
        backgroundColor: '#082E78 !important',
        color: '#FFF !important',
        fontSize: '12px',
        lineHeight: '12px',
        fontWeight: 600,
        fontFamily: 'Open Sans',
        borderRadius: '3px !important',
        flexShrink: '0',
        textAlign: 'center',
    },
    popupheader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '12px',
    },
    panName: {
        cursor: 'pointer',
        padding: '10px',
        fontWeight: 500,
    },
    pageSelector: {
        ...commonStyleSheet(theme).flexRow,
        border: '1px solid white',
        borderBottom: '0.5px solid #333 !important',
        backgroundColor: 'white',
        boxShadow: '2px 2px 6px 0 rgba(0,0,0,0.05)',
    },
    tabSelector: {
        padding: 13.5,
        ...commonStyleSheet(theme).flexRow,
        alignItems: 'center',
        width: '13%',
        minWidth: '125px',
        cursor: 'pointer',
    },
    basicform: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '1px',
        alignItems: 'baseline',
    },
    settingsform: {
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 0px 1px 23px',

    },
    container: {
        display: 'flex',
        paddingTop: '8px',
    },
    lefthalf: {
        width: '45%',
        padding: '10px',
    },
    righthalf: {
        fontSize: '13px',
        width: '55%',
        padding: '12px',
        borderRadius: '4px',
        // background: 'rgba(255, 230, 179, 0.20)',
    },
    cardContainer: {
        backgroundColor: '#FFFFFF',
        borderRadius: '8px',
        padding: '2px important',
        boxShadow: '0 1px 1px rgba(0, 0, 0, 0.1)',
        transition: 'transform 0.3s ease',
        marginBottom: '2px',
        marginTop: '5px',
        width: '254px',
        height: '162px',
        '&:hover': {
            transform: 'scale(1.05)',
            backgroundImage: 'linear-gradient(to bottom, rgba(173, 216, 230, 0.5), transparent)',
        },
    },
    pluginicon: {
        height: '40px',
        width: '40px',
        marginRight: '80%',
    },
    breadcrumb: {
        marginLeft: '2px',
        fontSize: '12px',
        fontWeight: 400,
        color: '#111111',
        lineHeight: '20px',
        display: 'flex',
        alignItems: 'center',
    },
    breadcrumbicon: {
        flexShrink: '0',
        marginRight: '2px',
    },

    breadcrumbitem: {
        color: '#111111',
        cursor: 'pointer',
    },

    label: {
        display: 'flex',
        alignItems: 'center',
        width: '16%',

    },
    discardbutton: {
        fontSize: '12px',
        color: '#111',
        fontWeight: 600,
        width: '59px',
        height: '32px',
        cursor: 'pointer',
        marginRight: '1rem',
        padding: '3px',
        border: 'none !important',
    },
    savebutton: {
        width: '59px',
        height: '32px',
        fontSize: '12px',
        color: '#111',
        fontWeight: 600,
        backgroundColor: '#CCC  !important',
        borderRadious: '8px',
        cursor: 'pointer',
    },
    recipienttype: {
        width: '100px !important',
        height: '32px',
        fontSize: '12px',
        color: '#111 !important',
        borderRadius: '4px',
    },
    typeinput: {
        height: '32px !impotant',
        border: '1px solid #D9D9D9',
        borderRadius: '4px !important',
        fontSize: '12px',
        color: '#111 !important',
    },
    upperlabel: {
        display: 'flex',
        marginBottom: '10px',
        fontSize: '10px',
        fontWeight: 400,
        color: '#333 !improtant',
    },
    cross: {
        color: '#EEE !important',
        cursor: 'pointer',
    },

    text: {
        margin: '2% 0px 2% 10px',
        fontSize: '12px',
        fontWeight: 400,
        color: '#333',
    },
    switch: {
        // height: '12px',
        // width: '21px',
        marginRight: '10px',
    },
    step1: {
        fontWeight: 600,
        fontSize: '12px',
        marginRight: '6.5%',
    },
    hrline: {
        backgroundColor: '#333',
        height: '0.5px',
        // margin: '0px 22px 0px 22px',
    },
    generatebutton: {
        color: '#006EC3 !important',
        border: '1px solid #006EC3 !important',
        fontSize: '12px',
        fontWeight: 600,
    },
    state: {
        fontSize: '12px',
        fontWeight: 600,
        marginBottom: '12px',
    },
    menuitem: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',

    },
    switchlabel: {
        fontSize: '12px',
        fontWeight: 600,
        marginTop: '8px',
    },
    channels: {
        marginTop: '10px',
    },
    leftmenuheading: {
        fontSize: '10px',
        fontWeight: 400,
        marginTop: '10px',
    },
    dimensionlabel: {
        fontSize: '12px',
        fontFamily: 'Open Sans',
        fontWeight: 400,
        color: '#333',
        marginLeft: '3px',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        padding: '12px',
        justifyContent: 'space-between',
    },
    link: {
        color: 'blue',
        textDecoration: 'underline',
    },
    doclinks: {
        marginLeft: '20px',
        display: 'flex',
        alignItems: 'center',
        fontSize: '10px',
    },
    spanlink: {
        right: '70px',
    },
    bottomHeading: {
        fontSize: '14px',
        fontWeight: '600',
        marginBottom: '20px',
    },
    mytable: {
        '& .ant-table-thead > tr > th': {
            fontSize: '10px',
        },
        '& .ant-table-tbody > tr > td': {
            fontSize: '12px',
            fontWeight: 600,
        },
    },
    tableswitch: {
        height: '12px',
        width: '21px',
    },
    img: {
        height: '40px',
        width: '40px',
        transition: 'all 0.3s ease',
    },
    img1: {
        height: '100px',
        width: '100px',
        transition: 'all 0.3s ease',
    },
    textShopify: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
});


interface ChannelIntegrationprops extends StylesProps<ReturnType<typeof styles>> {
    master: Master;
}
const ChannelIntegrationpage = (props: ChannelIntegrationprops) => {
    const { classes, master } = props;
    const bannerHeight = master?.config?.is_using_new_customer_portal ? 0 : 46;
    const loadTypeOptions = master?.config?.load_type_options || [];
    const requiredfields = ['store_url', 'order_pull_step'];
    const isRequired = (key: string) => {
        return requiredfields.includes(key);
    };
    const [form] = Form.useForm();
    const pluginsList = ['Shopify'];
    const allList = ['Shopify'];
    const tabslist = ['Basic Details', 'Settings'];
    const Consignmentstates = ['In Transit', 'RTO Delivered', 'RTO Initiated', 'Out For Delivery', 'Delivered', 'Cancelled'];

    const [currentStep, setCurrentStep] = React.useState<string>('pluginpage');
    const [pluginName, setPluginName] = React.useState<string>();
    const [showlist, setShowlist] = React.useState<string>('all');
    const [creating, setCreating] = React.useState<boolean>(false);
    const [services, setServices] = React.useState<IdName[]>([]);
    const [formData, setFormData] = React.useState<any>({});
    const [loading, setLoading] = React.useState<boolean>(false);
    const [pluginStores, setPluginStores] = React.useState<any[]>([]);
    const [currentPage, setCurrentPage] = React.useState<string>('Basic Details');

    const loadAllPluginStores = async () => {
        const response = await getPluginStoresList();
        if (response.isSuccess) {
            setPluginStores(response?.data?.page_data);
        } else {
            message.error(response.errorMessage);
        }
    };
    const loadServices = async () => {
        const response = await getServiceList({
            isInternational: 'false',
        });
        setServices(response?.data || []);
    };
    const prefillData = () => {
        form.setFieldsValue(formData);
    };
    React.useEffect(() => {
        loadServices();
        loadAllPluginStores();
    }, []);

    React.useEffect(() => {
        prefillData();
    }, [currentStep]);

    const renderInput = (field: FormField) => (
        <Form.Item
            name={field.key}
            initialValue={field.defaultValue}
            valuePropName={field.valuePropName}
            rules={[{ required: isRequired(field.key), message: 'Required' }]}
        >
            <Input
                type={field.type}
                min={0}
                autoComplete="off"
                placeholder={field.placeholder}
                style={{ width: field.width, fontSize: '12px', marginRight: 5 }}
                value={field.defaultValue?.toString()}
                width={field.width}
                disabled={field.disabled}
            />
        </Form.Item>
    );
    const serviceNames = services.map((service) => ({
        label: service.name,
        value: service.name,
    }));

    const renderswitch = (field: FormField) => (
        <Form.Item
            name={field.key}
            initialValue={field.defaultValue}
            valuePropName="checked"
            getValueFromEvent={(checked) => checked}
            trigger="onChange"
            rules={[{ required: isRequired(field.key), message: 'Required' }]}
        >
            <Switch
                size="small"
                defaultChecked={false}
                className={classes.switch}
                disabled={field.disabled}
            />
        </Form.Item>
    );

    const renderSelect = (field: FormField) => {
        let options = field.options;
        if (field.key === 'default_domestic_service_type') {
            options = serviceNames;
        }
        if (field.key === 'default_load_type') {
            options = loadTypeOptions;
        }
        return (
            <Form.Item
                name={field.key}
                initialValue={field.defaultValue}
                valuePropName={field.valuePropName}
                rules={[{ required: isRequired(field.key), message: 'Required' }]}
            >
                <Select
                    options={options}
                    mode={field.mode}
                    placeholder={field.placeholder}
                    style={{ width: field.width }}
                    allowClear
                    disabled={field.disabled}
                />
            </Form.Item>
        );
    };

    const renderTextArea = (field: FormField) => (
        <Form.Item
            name={field.key}
            initialValue={field.defaultValue}
            valuePropName={field.valuePropName}
            rules={[{ required: isRequired(field.key), message: 'Required' }]}
        >
            <TextArea
                placeholder={field.placeholder}
                style={{ width: field.width }}
                value={field.defaultValue?.toString()}
            />
        </Form.Item>
    );

    const renderFormItem = (field: FormField | undefined) => {
        if (!field) {
            return (
                <div className={classes.formitem} />
            );
        }
        switch (field.type) {
            case InputTypes.Input:
                return (
                    <div className={classes.formitem}>
                        <div className={classes.label} style={{ width: field.titleWidth }}>
                            {field.label}
                            {isRequired(field.key) ? '*' : ''}
                        </div>
                        <div className={classes.inputform}>
                            {renderInput(field)}
                        </div>
                    </div>
                );
            case InputTypes.Switch:
                return (
                    <div className={classes.formitem} style={{ marginBottom: 0 }}>
                        <div className={classes.switchform}>
                            {renderswitch(field)}
                        </div>
                        <div className={classes.switchlabel} style={{ width: field.titleWidth }}>
                            {field.label}
                        </div>
                    </div>
                );
            case InputTypes.Select:
                return (
                    <div className={classes.formitem}>
                        <div className={classes.label} style={{ width: field.titleWidth }}>
                            {field.label}
                            {isRequired(field.key) ? '*' : ''}
                        </div>
                        <div className={classes.inputform}>
                            {renderSelect(field)}
                        </div>
                    </div>
                );
            case InputTypes.Textarea:
                return (
                    <div className={classes.formitem}>
                        <div className={classes.title}>
                            {field.label}
                            {isRequired(field.key) ? '*' : ''}
                        </div>
                        {renderTextArea(field)}
                    </div>
                );
            default:
                return (<h1>N/A</h1>);
        }
    };



    const handlepluginclick = (plugin: string) => {
        setPluginName(plugin);
        setCurrentStep('integrationsPage');
    };

    const customCheck = async () => {
        await form.validateFields(requiredfields);
    };



    const renderPlugin = (plugin: any) => {
        return (
            <>
                <Card
                    className={classes.cardContainer}
                    onClick={() => handlepluginclick(plugin)}
                >
                    <img
                        alt="success"
                        src={ShopifyIcon}
                        className={classes.img}
                    />
                    <div className={classes.plugin}>
                        {plugin}
                    </div>
                    <p className={classes.plugindescription}>
                        This is shopify plugin click on it to connect to the plugin
                    </p>
                    <div className={classes.plugintype}>
                        E-commerce Plugins
                    </div>
                </Card>
            </>
        );
    };

    const renderchannellist = () => {
        if (showlist === 'ecommerceplugin') {
            return (
                pluginsList.map((plugin) => (
                    <>
                        {renderPlugin(plugin)}
                    </>
                ))
            );
        }
        return (
            allList.map((item) => (
                <>
                    {renderPlugin(item)}
                </>
            ))
        );
    };
    const handleTabChange = async (page: any) => {
        if (page === currentPage) {
            return;
        }
        setFormData({
            ...formData,
            ...await form.validateFields(),
        });
        setCurrentPage(page);
    };

    const renderTabDetails = (page: any) => {
        const activePage = page === currentPage;
        return (
            <div
                style={{
                    color: activePage ? '#111' : '#999',
                    fontWeight: activePage ? 700 : 400,
                    fontSize: '14px',
                    marginLeft: 8,
                }}
            >
                {page}
            </div>
        );
    };
    const renderTab = (page: string) => {
        const activePage = page === currentPage;
        return (
            <div
                key={page}
                className={classes.tabSelector}
                onClick={() => handleTabChange(page)}
                style={{ borderBottom: activePage ? '2px solid #0072D0' : 'none' }}
            >
                {renderTabDetails(page)}
            </div>
        );
    };

    const renderbasicdetails = () => {
        if (currentPage !== 'Basic Details') return null;
        return (
            <div className={classes.container}>
                <div className={classes.lefthalf}>
                    <div className={classes.basicform}>
                        {renderFormItem(appId)}
                        {renderFormItem(appsecret)}
                        {renderFormItem(accesstoken)}
                        {renderFormItem(redirectionUrl)}
                        {renderFormItem(callbackUrl)}
                        {renderFormItem(webhookurl)}
                    </div>
                </div>
                <div className={classes.righthalf} />
            </div>
        );
    };
    const renderstate = (state: any) => {
        return (
            <div className={classes.state}>{state}</div>
        );
    };
    const renderDefaultDimensions = (field: FormField) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', margin: '5px 0px 6px 0px' }}>
                <div className={`${classes.label} ${classes.dimensionlabel}`}>
                    {field.label}
                    {isRequired(field.key) ? '*' : ''}
                </div>
                <div className={classes.inputform} style={{ display: 'flex', flexDirection: 'row' }}>
                    {renderInput(defaultLength)}
                    {renderInput(defaultHeight)}
                    {renderInput(defaultWidth)}
                    {renderInput(defaultVolumeUnit)}
                </div>
            </div>
        );
    };

    const renderDefaultWeight = (field: FormField) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', margin: '5px 0px 6px 0px' }}>
                <div className={`${classes.label} ${classes.dimensionlabel}`}>
                    {field.label}
                    {isRequired(field.key) ? '*' : ''}
                </div>
                <div className={classes.inputform} style={{ display: 'flex', flexDirection: 'row' }}>
                    {renderInput(defaultWeight)}
                    {renderInput(defaultWeightUnit)}
                </div>
            </div>
        );
    };

    const renderAutoShipment = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', margin: '20px 0px 6px 0px' }}>
                <div className={`${classes.label} ${classes.dimensionlabel} marginLeft: '10px'`}>
                    {autoShipment.label}
                    {isRequired(autoShipment.key) ? '*' : ''}
                </div>
                <div className={classes.inputform} style={{ display: 'flex', flexDirection: 'row' }}>
                    {renderswitch(autoShipment)}
                </div>
            </div>
        );
    };

    const renderSettings = () => {
        if (currentPage !== 'Settings') return null;
        return (
            <div className={classes.settingsform}>
                {renderFormItem(trackingUpdates)}
                <div style={{ marginLeft: '3.7%' }}>
                    <h5 style={{ marginBottom: '20px' }}>
                        Event below will be sent to Shopify when the consignment reaches the given state
                    </h5>
                    {Consignmentstates.map((state) => renderstate(state))}
                </div>
                <div className={classes.horizontalline} style={{ marginBottom: '10px' }} />
                <div>
                    {renderFormItem(defaultDomesticServiceType)}
                    {renderDefaultDimensions(defaultHeight)}
                    {renderDefaultWeight(defaultWeight)}
                    {renderFormItem(defaultLoadType)}
                    {renderFormItem(defaultContentType)}
                    {renderFormItem(orderPullStep)}
                    {renderAutoShipment()}
                </div>

            </div>
        );
    };

    const renderPageSelector = () => {
        return (
            <>
                <div
                    className={classes.pageSelector}
                >
                    {tabslist.map((page) => renderTab(page))}
                </div>
            </>
        );
    };

    const renderdetailsform = () => {
        return (
            <div>
                {renderPageSelector()}
                {renderbasicdetails()}
                {renderSettings()}
                {/* {renderCommuniation()} */}
            </div>
        );
    };

    const renderpluginpage = () => {
        if (currentStep !== 'pluginpage') return null;
        return (
            <div style={{ height: `calc(((100vh - ${NAVBAR_HEIGHT}px) - 62px) - ${bannerHeight}px)` }}>
                <div className={classes.leftmenucontainer}>
                    <div className={classes.leftmenu}>
                        <div className={classes.leftmenuheading}>Categories</div>
                        <Menu className={classes.sidemenu} defaultSelectedKeys={['all']}>
                            <Menu.Item
                                key="all"
                                onClick={() => setShowlist('all')}
                            >
                                <span className={classes.menuitem}>
                                    <span style={{ }}>
                                        <SettingIcon style={{ marginRight: '10px' }} />
                                        All
                                    </span>
                                    {allList.length}
                                </span>
                            </Menu.Item>
                            <Menu.Item
                                onClick={() => setShowlist('ecommerceplugin')}
                            >
                                <span className={classes.menuitem}>
                                    <span>
                                        <EcompluginIcon style={{ marginRight: '10px' }} />
                                        E-commerce Plugins
                                    </span>
                                    <span>
                                        {pluginsList.length}
                                    </span>
                                </span>
                            </Menu.Item>
                        </Menu>
                    </div>
                    <div className={classes.channels}>{renderchannellist()}</div>
                </div>
            </div>
        );
    };
    const handleclickHome = () => { setCurrentStep('pluginpage'); };
    const handleclickEcom = (plugin: string) => {
        setPluginName(plugin);
        setCurrentStep('integrationsPage');
    };

    async function checkForErrors() {
        const formValues = await form.validateFields();
        setCreating(true);
        const apiBody = {
            ...formData,
            ...formValues,
            is_active: true,
        };
        const response = await createPlugin(apiBody);
        if (response.isSuccess) {
            message.success(apiBody?.id ? 'Plugin updated successfully' : 'plugin created successfully');
            setCurrentStep('integrationsPage');
            loadAllPluginStores();
        } else {
            message.error(response.errorMessage);
        }
        setCreating(false);
    }




    const handlediscard = () => {
        setCurrentStep('integrationsPage');
    };
    const handlesave = async () => {
        await customCheck();
        checkForErrors();
    };
    const renderbuttons = () => {
        return (
            <div>
                <Button className={classes.discardbutton} onClick={handlediscard}>Discard </Button>
                <Button className={classes.savebutton} onClick={handlesave} loading={creating}>Save</Button>
            </div>
        );
    };


    const renderBroadcrumb = (plugin: string) => {
        return (
            <Breadcrumb separator=">" className={classes.breadcrumb}>
                <Breadcrumb.Item className={classes.breadcrumbitem} onClick={handleclickHome}>
                    <HomeIcon className={classes.breadcrumbicon} />
                    Home
                </Breadcrumb.Item>
                <Breadcrumb.Item className={classes.breadcrumbitem} onClick={() => handleclickEcom(plugin)}>
                    <EcompluginIcon className={classes.breadcrumbicon} />
                    {plugin}
                </Breadcrumb.Item>
                {currentStep === 'infopage' ? (
                    <Breadcrumb.Item className={classes.breadcrumbitem}>
                        + Add Ecommerce Plugin
                    </Breadcrumb.Item>
                )
                    : null}
            </Breadcrumb>
        );
    };

    const handleEditPlugin = async (record: any) => {
        setFormData({
            id: record.id,
            app_name: record.appName,
            store_url: record.storeUrl,
            order_pull_step: record.orderPullStep,
            auto_shipment: record.autoShipment,
            api_key: record.apiKey,
            api_secret: record.apiSecret,
            access_token: record.token,
            send_tracking_updates_to_shopify: record.sendTrackingUpdatesToShopify,
            default_domestic_service_type: record.defaultDomesticServiceType,
            default_length: record.defaultLength,
            default_height: record.defaultHeight,
            default_width: record.defaultWidth,
            default_weight: record.defaultWeight,
        });
        setCurrentStep('infopage');
    };

    const handleActiveChange = async (record: any) => {
        setLoading(true);
        const newRecord = {
            id: record.id,
            app_name: record.appName,
            store_url: record.storeUrl,
            api_key: record.apiKey,
            api_secret: record.apiSecret,
            access_token: record.token,
            send_tracking_updates_to_shopify: record.sendTrackingUpdatesToShopify,
            default_domestic_service_type: record.defaultDomesticServiceType,
            is_active: !record.isActive,
        };
        const response = await createPlugin(newRecord);
        if (response.isSuccess) {
            message.success(`Plugin ${record.isActive ? 'disabled' : 'enabled'} successfully`);
            setCurrentStep('integrationsPage');
            loadAllPluginStores();
        } else {
            message.error(response.errorMessage);
        }
        setLoading(false);
    };

    const handlePopup = (record: any) => {
        Modal.confirm({
            title: (
                <span>
                    Are you sure you want to
                    {record?.isActive ? ' disable ' : ' enable ' }
                    this plugin?
                </span>),
            okText: 'Yes',
            cancelText: 'No',
            onOk: async () => {
                handleActiveChange(record);
            },
            width: '33%',
            style: { width: '500px', borderRadius: '6px' },
        });
    };


    const columns = [
        {
            title: 'S.No.',
            dataIndex: 'serialNumber',
            key: 'serialNumber',
        },
        {
            title: 'Application Name',
            dataIndex: 'appName',
            key: 'appName',
        },
        {
            title: 'Status Update Webhook',
            dataIndex: 'sendTrackingUpdatesToShopify',
            key: 'sendTrackingUpdatesToShopify',
            render: (sendTrackingUpdatesToShopify: boolean) => (
                <>
                    {
                        sendTrackingUpdatesToShopify ? 'Yes' : 'No'
                    }
                </>
            ),
        },
        // {
        //     title: 'Updated By',
        //     dataIndex: 'updatedBy',
        //     key: 'updatedBy',
        // },
        {
            title: 'Updated At',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: (text: string) => (
                <span>
                    {moment(text).format('YYYY-MM-DD')}
                    {' | '}
                    {moment(text).format('HH:mm')}
                </span>
            ),
        },
        {
            title: 'Active',
            dataIndex: 'isActive',
            key: 'isActive',
            render: (isActive: boolean, record: any) => (
                <Switch
                    // className={classes.tableswitch}
                    checked={isActive}
                    onChange={() => handlePopup(record)}
                    size="small"
                />
            ),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text: string, record: any) => (
                <Dropdown
                    overlay={
                        (
                            <Menu style={{ color: 'primary', borderRadius: '4px' }}>
                                <Menu.Item key="1">
                                    <Button
                                        type="text"
                                        onClick={() => handleEditPlugin(record)}
                                    >
                                        Edit
                                    </Button>
                                </Menu.Item>
                            </Menu>
                        )
                    }
                >
                    <Button type="text" icon={<MoreOutlined />} />
                </Dropdown>
            ),
        },
    ];
    // make empty array dataSource of type object

    const myTable = () => {
        return (
            <Table
                dataSource={pluginStores}
                columns={columns}
                loading={loading}
                pagination={false}
                className={classes.mytable}
            />
        );
    };
    const rendertags = () => {
        return (
            <div style={{ display: 'flex' }}>
                <Tag color="blue" style={{ color: '#333', fontSize: '10px' }}>integration</Tag>
                <Tag color="blue" style={{ color: '#333', fontSize: '10px' }}>webhook</Tag>
                <Tag color="blue" style={{ color: '#333', fontSize: '10px' }}>consignment</Tag>
            </div>
        );
    };

    const handleNewIntgration = () => {
        setFormData({});
        setCurrentStep('infopage');
    };

    const renderPluginHeader = () => {
        return (
            <div className={classes.header}>
                <div className={classes.header}>
                    <div>
                        <img
                            alt="success"
                            src={ShopifyIcon}
                            className={classes.img1}
                            onMouseEnter={(e) => {
                                const target = e.currentTarget;
                                target.style.height = '104px';
                                target.style.width = '104px';
                            }}
                            onMouseLeave={(e) => {
                                const target = e.currentTarget;
                                target.style.height = '100px';
                                target.style.width = '100px';
                            }}
                        />
                    </div>
                    <div>
                        <span style={{ fontSize: '24px', fontWeight: 700 }}>
                            {pluginName}
                        </span>
                        <span style={{ fontSize: '10px', fontStyle: 'italic', marginLeft: '10px' }}>
                            E-commerce Plugin
                        </span>
                        {rendertags()}
                    </div>
                </div>
                <div style={{ marginLeft: '10px', right: '30px' }}>
                    <Button
                        onClick={() => handleNewIntgration()}
                        style={{
                            backgroundColor: '#006EC3',
                            color: 'white',
                            marginTop: '20px',
                            borderRadius: '4px',
                            fontWeight: 600,
                        }}
                    >
                        Add Ecommerce Plugin
                    </Button>
                </div>
            </div>
        );
    };
    const renderFooter = () => {
        return (
            <div className={classes.header} style={{ marginTop: '15px' }}>
                <div style={{ width: '35%' }}>
                    <span>
                        <GlobalOutlined style={{ marginRight: '10px' }} />
                    </span>
                    <span className={classes.bottomHeading}>
                        Overview
                    </span>
                    <div className={classes.textShopify}>
                        <p style={{ fontSize: '12px', paddingLeft: '6.5%', textAlign: 'justify' }}>
                            Shopify is a user-friendly e-commerce platform that empowers businesses to create
                            and manage online stores. It offers customizable templates for website design,
                            seamless product management, payment processing, and responsive shopping carts.
                            Users can efficiently manage inventory, process orders, and integrate with shipping
                            carriers. Shopify&apos;s themes adapt to various devices, and it supports customization
                            through a theme editor or custom code. An extensive app store provides additional
                            features. Built-in marketing tools, analytics, and security features are included.
                            Shopify simplifies online business, making it accessible to users of all levels of
                            experience, backed by various support options.
                        </p>
                    </div>

                </div>
                <div style={{ width: '20%', marginLeft: '20px' }}>
                    <span>
                        <LinkOutlined style={{ marginRight: '10px' }} />
                    </span>
                    <span className={classes.bottomHeading} style={{ marginRight: '8px' }}>
                        Integrations
                    </span>
                    <span>
                        <InfoCircleFilled style={{ marginLeft: '8px' }} />
                    </span>
                    <ol style={{ fontSize: '12px' }}>
                        <li>
                            Order Fulfilment create
                        </li>
                        <li>
                            Order Fulfilment update
                        </li>
                        <li>
                            Order Cancellation
                        </li>
                    </ol>
                </div>
                <div style={{ width: '35%', marginLeft: '20px' }}>
                    <span>
                        <FileTextOutlined style={{ marginRight: '10px' }} />
                    </span>
                    <span className={classes.bottomHeading}>
                        Documentations and Supprt
                    </span>
                    <span>
                        <InfoCircleFilled style={{ marginLeft: '8px' }} />
                    </span>
                    <div className={classes.doclinks}>
                        <span style={{ marginRight: '5px' }}>
                            Help Documentation
                        </span>
                        <span className={classes.spanlink}>
                            <a
                                href="https://shipsy-public-assets.s3.amazonaws.com/generic/file-ba7878c4-c20e-4fff-b2b6-4a2ce7cba542.pdf" // eslint-disable-line
                                className={classes.link}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Link
                            </a>
                        </span>
                    </div>
                    <div className={classes.doclinks}>
                        <span style={{ marginRight: '5px' }}>
                            Application Testing Documentation
                        </span>
                        <span className={classes.spanlink}>
                            <a
                                href="https://shopify.dev/changelog/we-re-making-it-easier-for-app-developers-to-test-webhook-topics-and-streamlining-webhook-failure-emails" // eslint-disable-line
                                className={classes.link}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Link
                            </a>
                        </span>
                    </div>
                    <div className={classes.doclinks}>
                        <span style={{ marginRight: '5px' }}>
                            Shopify API Documentation
                        </span>
                        <span className={classes.spanlink}>
                            <a
                                href="https://shopify.dev/docs/api/admin-rest/2023-07/resources/webhook"
                                className={classes.link}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Link
                            </a>
                        </span>
                    </div>
                    <div className={classes.doclinks}>
                        <span style={{ marginRight: '5px' }}>
                            About Shopify
                        </span>
                        <span className={classes.spanlink}>
                            <a
                                href="https://www.shopify.com/in/about"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={classes.link}
                            >
                                Link
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        );
    };
    const renderIntegrationsPage = (pluginname: any) => {
        if (currentStep !== 'integrationsPage') return null;
        return (
            <>
                <div
                    className={classes.page}
                    style={{ height: `calc(((100vh - ${NAVBAR_HEIGHT}px) - 62px) - ${bannerHeight}px)` }}
                >
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        {renderBroadcrumb(pluginname)}
                    </div>
                    <div>
                        {renderPluginHeader()}
                        {myTable()}
                        <div className={classes.horizontalline} />
                        {renderFooter()}
                    </div>
                </div>
            </>
        );
    };

    const renderinfopage = (pluginname: any) => {
        if (currentStep !== 'infopage') return null;
        return (
            <>
                <div
                    className={classes.page}
                    style={{ height: `calc(((100vh - ${NAVBAR_HEIGHT}px) - 62px) - ${bannerHeight}px)` }}
                >
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        {renderBroadcrumb(pluginname)}
                        {renderbuttons()}
                    </div>
                    <div className={classes.block}>
                        {renderFormItem(appName)}
                        {renderFormItem(storeUrl)}
                    </div>
                    {renderdetailsform()}
                </div>
            </>
        );
    };

    return (
        <Form
            form={form}
            className={classes.main}
        >
            {renderpluginpage()}
            {renderIntegrationsPage(pluginName)}
            {renderinfopage(pluginName)}
        </Form>
    );
};

const mapStateToProps = (state: ReduxStore) => {
    const { master } = state;

    return {
        master,
    };
};

const hocConfig: HocOptions = {
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectRouter: true,
    connectTranslession: true,
};

const ChannelIntegration = GenericHoc(hocConfig)(ChannelIntegrationpage);


export default (ChannelIntegration);
