import { API_BASE_URL, BUSINESS_BOOKING_API_URL } from '../library/globals';
import { GET, POST } from './api-hander.service';
import AuthHelper from '../auth/auth-helper';
import {
    BULK_CONSIGNMENT,
    BULL_PRINT_CONSIGNMENT,
    CREATE_CONSIGNMENT,
    DOWNLOAD_CONSIGNMENTS,
    FETCH_CONSIGNMENT,
    FETCH_CONSIGNMENTS,
    FETCH_SUMMARY_COUNT,
    GET_CONSIGNMENT_TRACKING,
    GET_CONTENT_LIST,
    GET_CURRENCY_CODES,
    GET_PUDO_HUBS,
    GET_STATE_CITY,
    GET_TIME_RANGES,
    PRINT_ADDRESS_LABEL,
    PRINT_CONSIGNMENT,
    PRINT_POD_CONSIGNMENT,
    PRINT_ROUTING_LABEL,
    SAMPLE_DOWNLOAD,
    UPDATE_COLUMNS,
    UPDATE_FILTERS,
    CREATE_CONSIGNMENT_INTERNATIONAL,
    GET_CHILD_LIST,
    BULK_CONSIGNMENT_INTERNATIONAL,
    PRINT_INVOICE,
    GET_INCO_TERMS,
    GET_PUBLISHED_LABELS,
    CANCEL_CONSIGNMENTS,
    REVOKE_CANCELLATION,
    GET_CANCELLATION_REASONS,
    LOGIN_CHILD_CUSTOMER,
    SET_CONSIGNMENT_NOTES,
    PRINT_4X6_CONSIGNMENT,
    PRINT_4X6_CONSIGNMENT_DOMESTIC,
    GET_RTO_CONSIGNMENTS,
    RTO_ACTION,
    SAVE_PICKUP,
    GET_REMITTANCE_DATA,
    GET_INVOICE_DATA,
    DOWNLOAD_INVOICE_REPORT,
    GET_INVOICE_DOWNLOAD_REQUESTS,
    DOWNLOAD_INVOICE_REPORT_REQUEST,
    CREATE_DOWNLOAD_REMITTANCE_DATA,
    FETCH_PLUGIN_ORDERS,
    DOWNLOAD_PLUGIN_ORDERS,
    SAVE_PLUGIN_ORDER,
    FETCH_ITEM_UNITS,
    PRINT_BULK_LABEL,
    GET_VALIDATION_BUILDER_DATA,
    DOWNLOAD_CONSIGNMENTS_SOFTDATA,
} from './api.constants';
import {
    UpdateColumns,
    UpdateFilters,
} from './consignment-api.types';

export const fetchConsignments = async (body: any) => {
    const response = await POST(`${API_BASE_URL}${FETCH_CONSIGNMENTS}`, body);
    return response;
};

export const fetchPluginOrders = async (body: any) => {
    const response = await POST(`${API_BASE_URL}${FETCH_PLUGIN_ORDERS}`, body);
    return response;
};

export const fetchSummary = async (body: any) => {
    const response = await POST(`${API_BASE_URL}${FETCH_SUMMARY_COUNT}`, body);
    return response;
};

export const getContentList = async (params: any) => {
    return GET(`${BUSINESS_BOOKING_API_URL}${GET_CONTENT_LIST}`, params);
};

export const getChildList = async (params: any = {}) => {
    return GET(`${BUSINESS_BOOKING_API_URL}${GET_CHILD_LIST}`, params, undefined, AuthHelper.getAuthenticationHeaders());
};

export const getCurrencyCodes = async (params: any = {}) => {
    return GET(`${BUSINESS_BOOKING_API_URL}${GET_CURRENCY_CODES}`, params);
};

export const getTimeSlots = async (params: any) => {
    return GET(`${BUSINESS_BOOKING_API_URL}${GET_TIME_RANGES}`, params);
};

export const getCityState = async (params: any) => {
    return GET(`${BUSINESS_BOOKING_API_URL}${GET_STATE_CITY}`, params);
};

export const createConsignment = async (body: any) => {
    if (body.consignmentCategory === 'international') {
        return POST(`${BUSINESS_BOOKING_API_URL}${CREATE_CONSIGNMENT_INTERNATIONAL}`, body);
    }
    return POST(`${BUSINESS_BOOKING_API_URL}${CREATE_CONSIGNMENT}`, body, undefined, AuthHelper.getAuthenticationHeaders());
};

export const UpdatePluginOrder = async (body: any) => {
    return POST(`${API_BASE_URL}${SAVE_PLUGIN_ORDER}`, body);
};

export const fetchConsignment = async (params: any) => {
    return GET(`${API_BASE_URL}${FETCH_CONSIGNMENT}`, params);
};

export const fetchConsignmentTracking = async (params: any) => {
    // return GET(`${API_BASE_URL}${GET_CONSIGNMENT_TRACKING}`, params);
    return GET(`${BUSINESS_BOOKING_API_URL}${GET_CONSIGNMENT_TRACKING}`, params);
};

export const downloadConsignments = async (body: any) => {
    return POST(`${API_BASE_URL}${DOWNLOAD_CONSIGNMENTS}`, body);
};

export const downloadConsignmentsSoftdata = async (body: any) => {
    return POST(`${API_BASE_URL}${DOWNLOAD_CONSIGNMENTS_SOFTDATA}`, body);
};

export const downloadPluginOrders = async (body: any) => {
    return POST(`${API_BASE_URL}${DOWNLOAD_PLUGIN_ORDERS}`, body);
};

export const printConsignment = (body: any) => {
    return POST(`${BUSINESS_BOOKING_API_URL}${PRINT_CONSIGNMENT}`, body, true);
};

export const printConsignment4x6 = (body: any) => {
    return POST(`${BUSINESS_BOOKING_API_URL}${PRINT_4X6_CONSIGNMENT}`, body);
};

export const printConsignment4x6Domestic = (body: any) => {
    return POST(`${BUSINESS_BOOKING_API_URL}${PRINT_4X6_CONSIGNMENT_DOMESTIC}`, body, true);
};

export const downloadConsignments4X6Files = (fileUrl: string) => {
    return GET(fileUrl, {}, 'blob', {});
};

export const printPodShippingLabel = (params: any) => {
    return GET(`${BUSINESS_BOOKING_API_URL}${PRINT_POD_CONSIGNMENT}`, params, 'blob');
};

export const printRoutingLabel = (body: any) => {
    return POST(`${BUSINESS_BOOKING_API_URL}${PRINT_ROUTING_LABEL}`, body, true);
};

export const printInvoice = (body: any) => {
    return POST(`${BUSINESS_BOOKING_API_URL}${PRINT_INVOICE}`, body, true);
};

export const printAddressLabel = (params: any) => {
    return GET(`${BUSINESS_BOOKING_API_URL}${PRINT_ADDRESS_LABEL}`, params, 'blob');
};

export const bulkPrint = async (body: any) => {
    return POST(`${BUSINESS_BOOKING_API_URL}${BULL_PRINT_CONSIGNMENT}`, body, true);
};

export const downloadSample = async (params: any) => {
    return GET(`${BUSINESS_BOOKING_API_URL}${SAMPLE_DOWNLOAD}`, params, 'blob');
};

export const createBulk = async (body: any) => {
    return POST(`${BUSINESS_BOOKING_API_URL}${BULK_CONSIGNMENT}`, body, undefined, AuthHelper.getAuthenticationHeaders());
};

export const createBulkInternational = async (body: any) => {
    return POST(`${BUSINESS_BOOKING_API_URL}${BULK_CONSIGNMENT_INTERNATIONAL}`, body);
};

export const downloadPod = async (body: any) => {
    return POST(`${BUSINESS_BOOKING_API_URL}${BULK_CONSIGNMENT}`, body);
};

export const updateColumns = async (body: UpdateColumns) => {
    return POST(`${API_BASE_URL}${UPDATE_COLUMNS}`, body);
};

export const updateFilters = async (body: UpdateFilters) => {
    return POST(`${API_BASE_URL}${UPDATE_FILTERS}`, body);
};

export const getPudoHubs = async () => {
    return GET(`${BUSINESS_BOOKING_API_URL}${GET_PUDO_HUBS}`);
};

export const getValidationData = async (action: string) => {
    return GET(`${API_BASE_URL}${GET_VALIDATION_BUILDER_DATA}?action=${action}`);
};

export const getIncoTerms = async () => {
    return GET(`${BUSINESS_BOOKING_API_URL}${GET_INCO_TERMS}`);
};

export const getPublishedLabels = async () => {
    return GET(`${API_BASE_URL}${GET_PUBLISHED_LABELS}`);
};

export const cancelConsignments = (params: any) => {
    return POST(`${BUSINESS_BOOKING_API_URL}${CANCEL_CONSIGNMENTS}`, params);
};

export const revokeCancellation = (params: any) => {
    return POST(`${BUSINESS_BOOKING_API_URL}${REVOKE_CANCELLATION}`, params);
};

export const getCancellationReason = () => {
    return GET(`${BUSINESS_BOOKING_API_URL}${GET_CANCELLATION_REASONS}`);
};

export const loginToChildCustomer = (params: any) => {
    return POST(`${API_BASE_URL}${LOGIN_CHILD_CUSTOMER}`, params, undefined, AuthHelper.getAuthenticationHeaders());
};

export const setConsignmentNotes = (params: any) => {
    return POST(`${BUSINESS_BOOKING_API_URL}${SET_CONSIGNMENT_NOTES}`, params);
};

export const getRtoConsignments = (params: any) => {
    return GET(`${API_BASE_URL}${GET_RTO_CONSIGNMENTS}`, params);
};

export const rtoAction = (params: any) => {
    return POST(`${API_BASE_URL}${RTO_ACTION}`, params);
};

export const schedulePickup = (params: any) => {
    return POST(`${BUSINESS_BOOKING_API_URL}${SAVE_PICKUP}`, params);
};

export const getCODRemittanceReport = (params: any) => {
    return GET(`${BUSINESS_BOOKING_API_URL}${GET_REMITTANCE_DATA}`, params);
};

export const getInvoiceReport = (params: any) => {
    return GET(`${BUSINESS_BOOKING_API_URL}${GET_INVOICE_DATA}`, params);
};

export const downloadInvoiceReportRequest = async (body: any) => {
    return POST(`${API_BASE_URL}${DOWNLOAD_INVOICE_REPORT_REQUEST}`, body);
};

export const downloadInvoiceReport = async (body: any) => {
    return GET(`${BUSINESS_BOOKING_API_URL}${DOWNLOAD_INVOICE_REPORT}`, body);
};

export const getInvoiceDownloadRequests = async (params: any) => {
    return GET(`${API_BASE_URL}${GET_INVOICE_DOWNLOAD_REQUESTS}`, params);
};

export const createDownloadRequestCODRemittance = (params: any) => {
    return POST(`${API_BASE_URL}${CREATE_DOWNLOAD_REMITTANCE_DATA}`, params);
};

export const fetchItemUnits = () => {
    const payload = {
        is_active: true,
    };
    return GET(`${API_BASE_URL}${FETCH_ITEM_UNITS}`, payload);
};

export const printBulkLabel = (params: any) => {
    return POST(`${BUSINESS_BOOKING_API_URL}${PRINT_BULK_LABEL}`, params, true);
};
