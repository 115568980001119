/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import withStyles from 'react-jss';
import Loader from '../../common/Loader';
import CreateAddress from '../../address/create-address';
import AddressIcon from '../single-consignment-icons/address';
import { StylesProps, ThemeType } from '../../../theme/jss-types';
import { ConsignorAddressType, consignorFormFields, formFields } from '../create-modal.constants';
import { stepTwoStyles } from '../single-consignment.styles';
import { PickupAddress } from '../../pickup/create-pickup/pickup.types';
import { useDebounce } from '../../../hooks/use-debounce';
import {
    deleteAddress,
    fetchPickupAddress,
    getDefaultAddress,
    setDefaultAddressData,
    editAddress as editBusinessAddress,
} from '../../../network/pickup.api';
import {
    Button,
    Checkbox,
    Form,
    FormInstance,
    Input,
    message,
    Popconfirm,
    Radio,
} from 'antd';
import Helper from '../../../library/Helper';
import {
    BookOutlined,
    CheckCircleFilled, DeleteOutlined, EditOutlined, ExclamationCircleFilled,
} from '@ant-design/icons';
import { isEqual, uniqWith } from 'lodash';
import { Master } from 'types/master-data-types';
import { formRules } from 'library/constants';
import { useTranslation } from 'react-i18next';
import { ReduxStore } from 'reducers/redux.types';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { consignmentCategoryTypes, consignmentLocationTypes } from 'components/consignments/consignments.constants';

const {
    AddressTypes,
} = formFields;

const {
    ConsignorName,
    ConsignorAddressLine1,
    ConsignorAddressLine2,
    ConsignorCity,
    ConsignorState,
    ConsignorCountry,
    ConsignorPincode,
    ConsignorPhone,
    ConsignorEmail,
} = consignorFormFields;

const { useState } = React;
interface StepTwoProps extends StylesProps<ReturnType<typeof stepTwoStyles>> {
    page: 'Consignee' | 'Consignor';
    formData: any;
    form: FormInstance;
    addressKey: string;
    setFormData: any;
    phoneRegex: any;
    allowDefaultSourceAddress: boolean;
    fieldsToShowInternational: Record<any, boolean>;
    config: Master;
    pageTitle: string;
    countryWisePhoneRegex: any[];
    isRTL: any,
    uiTheme: ThemeType;
    consignorAddressType: any;
    setConsignorAddressType: any;
    sameChecked: Record<'billToDetails'|'srcAddressConsignor'|'retAddressConsignor'|'retAddressSrc', boolean>;
    setSameChecked: any;
    shouldUseSenderProfile?: boolean;
}

const StepTwo = (props: StepTwoProps) => {
    const {
        form,
        page,
        classes,
        formData,
        addressKey,
        setFormData,
        phoneRegex,
        countryWisePhoneRegex,
        allowDefaultSourceAddress,
        fieldsToShowInternational,
        config,
        pageTitle,
        isRTL,
        uiTheme,
        consignorAddressType,
        setConsignorAddressType,
        sameChecked,
        setSameChecked,
        shouldUseSenderProfile,
    } = props;

    const { t, i18n } = useTranslation();
    const formRuleRequired = {
        ...formRules.required,
        message: i18n.exists('required') ? t('required') : 'Required',
    };

    const isInternational = formData.consignmentCategory === consignmentCategoryTypes.INTERNATIONAL;
    const [consignorAddressDocument, setConsignorAddressDocument] = useState<PickupAddress | undefined>(undefined);
    const [updateAddress, setUpdateAddress] = useState<boolean>(false);
    const showAlternateAddress = config?.parts_to_show?.allow_multiple_alternate_address_in_fm_api;
    const defaultMultipleLimit = config?.config?.customer_portal_config?.default_addresses_limit;
    const isDestinationTypeRequired = config?.config?.customer_portal_config?.mandatory_shipment_commercial?.destinationType
        && isInternational && formData.shipmentPurpose === 'COMMERCIAL'
        && formData.courierType === 'non-document';
    const showAddressCode = config?.config?.customer_portal_config?.allow_address_code_in_addresses;
    const [defaultAddress, setDefaultAddress] = useState<Record<any, any>>({});
    const [defaultAddressSrc, setDefaultAddressSrc] = useState<Record<any, any>>({});
    const [isDefaultLoaded, setIsDefaultLoaded] = useState(false);
    const [consigneeType, setConsigneeType] = useState<string | undefined>(undefined);
    const [searchString, setSearchString] = useState<Record<string, string>>({
        srcAddress: '',
        dstAddress: '',
        alternateDelAddress1: '',
        alternateDelAddress2: '',
        redirectionAddress: '',
        billToDetails: '',
        retAddress: '',
        consignorAddress: '',
    });
    const [addressList, setAddressList] = useState<Record<string, PickupAddress[]>>({
        srcAddress: [],
        dstAddress: [],
        alternateDelAddress1: [],
        alternateDelAddress2: [],
        redirectionAddress: [],
        billToDetails: [],
        retAddress: [],
        consignorAddress: [],
    });
    const [addressListVisited, setAddressListVisited] = useState<Record<string, boolean>>({
        srcAddress: addressKey === 'srcAddress' || (addressKey === 'consignorAddress' && shouldUseSenderProfile as boolean),
        dstAddress: true,
        alternateDelAddress1: false,
        alternateDelAddress2: false,
        redirectionAddress: false,
        billToDetails: false,
        retAddress: false,
        consignorAddress: addressKey === 'consignorAddress' && !shouldUseSenderProfile,
    });
    const [addressTypeVisited, setAddressTypeVisited] = useState<Record<string, boolean>>({
        srcAddress: false,
        dstAddress: true,
        alternateDelAddress1: false,
        alternateDelAddress2: false,
        redirectionAddress: false,
        billToDetails: false,
        retAddress: false,
        consignorAddress: false,
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedAddress, setSelected] = React.useState<Record<string, PickupAddress | null>>({
        srcAddress: null,
        dstAddress: null,
        alternateDelAddress1: null,
        alternateDelAddress2: null,
        redirectionAddress: null,
        billToDetails: null,
        retAddress: null,
        consignorAddress: null,
    });
    const [addressType, setAddressType] = React.useState<Record<string, string>>({
        srcAddress: 'saved',
        dstAddress: 'saved',
        alternateDelAddress1: 'saved',
        alternateDelAddress2: 'saved',
        redirectionAddress: 'saved',
        billToDetails: 'saved',
        retAddress: 'saved',
        consignorAddress: 'saved',
    });
    const [addressTypeRadio, setAddressTypeRadio] = React.useState<string>(addressKey);
    const [editAddress, setEditAddress] = React.useState<PickupAddress | undefined>();
    const [pluginAddressType, setPluginAddressType] = React.useState<string>('');
    const [toggle, setToggle] = React.useState<boolean>(true);

    const loadDefaultAddress = async () => {
        setLoading(true);
        const response = await getDefaultAddress();
        if (!response.isSuccess) {
            message.error(response.errorMessage);
        } else {
            for (let i = 0; i < response.data.length; i += 1) {
                const entry = response.data[i];
                if (entry.type === 'srcAddress') {
                    setDefaultAddressSrc(entry);
                } else if (entry.type === 'dstAddress') {
                    setDefaultAddress(entry);
                }
            }
        }
        setLoading(false);
    };

    const loadAddress = async (key: string) => {
        if (addressType[key] !== 'saved') {
            return;
        }
        let defaultAdd : Record<any, any> = {};
        let defaultAddSrc : Record<any, any> = {};
        // eslint-disable-next-line no-console
        console.log(isDefaultLoaded);
        const result = await getDefaultAddress();
        if (result.isSuccess) {
            result.data = result?.data || {};
            for (let i = 0; i < result.data.length; i += 1) {
                const entry = result.data[i];
                if (entry.type === 'srcAddress') {
                    setDefaultAddressSrc(entry);
                    defaultAddSrc = entry;
                } else if (entry.type === 'dstAddress') {
                    setDefaultAddress(entry);
                    defaultAdd = entry;
                }
            }
            setIsDefaultLoaded(true);
        }
        let showDefaultBy = '';
        if (defaultMultipleLimit) {
            if (key === 'srcAddress') {
                showDefaultBy = 'src';
            } else {
                showDefaultBy = 'dst';
            }
        }
        setLoading(true);
        const isInternationalAddress = (isInternational && page === consignmentLocationTypes.CONSIGNEE);
        const response = await fetchPickupAddress({
            showDefaultBy,
            searchString: searchString[key] || '',
            ...(isInternational ? { isInternational: isInternationalAddress } : {}),
        });
        if (!response.isSuccess) {
            message.error(response.errorMessage);
        }
        let data = response?.data || [];
        if (page === 'Consignee') {
            // eslint-disable-next-line max-len
            data = data.filter((item: PickupAddress) => item.addType === 'Destination' || item.addType === 'Both');
        } else if (page === 'Consignor') {
            // eslint-disable-next-line max-len
            data = data.filter((item: PickupAddress) => item.addType === 'Origin' || item.addType === 'Both');
        }
        // eslint-disable-next-line no-underscore-dangle
        if ((defaultAdd?._id || defaultAddSrc?._id)
            && searchString[key].length < 1 && !defaultMultipleLimit) {
            let add: PickupAddress | undefined;
            data = await data.filter((item: PickupAddress) => {
                // eslint-disable-next-line no-underscore-dangle, max-len
                if ((item.id === defaultAdd._id && page === 'Consignee') || (item.id === defaultAddSrc._id && page === 'Consignor')) {
                    add = item;
                    return false;
                }
                return true;
            });
            if (add) await data.unshift(add);
            else if (page === 'Consignee' && Object.keys(defaultAdd).length > 0) {
                await data.unshift(defaultAdd);
            } else if (page === 'Consignor' && Object.keys(defaultAddSrc).length > 0) {
                await data.unshift(defaultAddSrc);
            }
        }
        if (formData?.previousSelectedAddress && formData?.previousSelectedAddress[key]) {
            data.unshift(formData?.previousSelectedAddress[key]);
        }
        setAddressList((oldAddressList) => {
            return {
                ...oldAddressList,
                [key]: uniqWith(data, isEqual),
            };
        });
        setLoading(false);
    };


    const saveDefaultAddress = async (address: PickupAddress, isMultipleDefault: boolean = false) => {
        setLoading(true);
        if (isMultipleDefault) {
            const response = await editBusinessAddress(address);
            if (!response.isSuccess) {
                message.error(response.errorMessage);
            } else {
                loadAddress(addressTypeRadio);
            }
        } else {
            const id = address.id;
            const response = await setDefaultAddressData({
                addressTypeRadio: page === 'Consignee' ? 'dstAddress' : 'srcAddress',
                businessAddressId: id,
            });
            if (!response.isSuccess) {
                message.error(response.errorMessage);
            } else {
                loadDefaultAddress();
                setLoading(true);
                const key = page === 'Consignee' ? 'dstAddress' : 'srcAddress';
                let data = addressList[addressTypeRadio] || [];
                // eslint-disable-next-line no-console
                console.log(allowDefaultSourceAddress);
                // eslint-disable-next-line no-underscore-dangle
                if (id && searchString[key].length < 1) {
                    let add: PickupAddress | undefined;
                    data = data.filter((item: PickupAddress) => {
                        // eslint-disable-next-line no-underscore-dangle
                        if (item.id === id) {
                            add = item;
                            return false;
                        }
                        return true;
                    });
                    if (add) await data.unshift(add);
                    else if (page === 'Consignee') {
                        await data.unshift(defaultAddress);
                    } else {
                        await data.unshift(defaultAddressSrc);
                    }
                    setAddressList((oldAddressList) => {
                        return {
                            ...oldAddressList,
                            [key]: data,
                        };
                    });
                }
            }
        }
        setLoading(false);
    };

    const prefillData = () => {
        form.setFieldsValue({
            srcAddress: formData.srcAddress,
            dstAddress: formData.dstAddress,
            alternateDelAddress1: formData.alternateDelAddress1,
            alternateDelAddress2: formData.alternateDelAddress2,
            redirectionAddress: formData.redirectionAddress,
            billToDetails: formData.billToDetails,
            consigneeCompanyName: formData.consigneeCompanyName,
            destinationType: formData.destinationType,
            receiverVATNumber: formData.receiverVATNumber,
        });
        setSelected({
            srcAddress: formData.srcAddress,
            dstAddress: formData.dstAddress,
            alternateDelAddress1: formData.alternateDelAddress1,
            alternateDelAddress2: formData.alternateDelAddress2,
            redirectionAddress: formData.redirectionAddress,
            billToDetails: formData.billToDetails,
            retAddress: formData.retAddress,
            consignorAddress: formData.consignorAddress,
        });
        if (formData.sameChecked) setSameChecked(formData.sameChecked);
    };

    React.useEffect(() => {
        if (!updateAddress) return;
        setUpdateAddress(false);
        if (selectedAddress[addressTypeRadio]) {
            let selectedAdd = null;
            addressList[addressTypeRadio].forEach((address) => {
                if (address.id === selectedAddress[addressTypeRadio]?.id) {
                    selectedAdd = address;
                }
            });
            setSelected({
                ...selectedAddress,
                [addressTypeRadio]: selectedAdd,
            });
            form.setFieldsValue({
                [addressTypeRadio]: selectedAdd,
            });
            setFormData({
                ...formData,
                [addressTypeRadio]: selectedAdd,
            });
        }
    }, [addressList]);

    const debounceTimeSrc = useDebounce(searchString.srcAddress);
    React.useEffect(() => {
        if (addressTypeRadio === 'srcAddress') {
            loadAddress('srcAddress');
        }
    }, [debounceTimeSrc, addressType.srcAddress, toggle, addressTypeVisited.srcAddress]);

    const debounceTimeDst = useDebounce(searchString.dstAddress);
    React.useEffect(() => {
        if (addressTypeRadio === 'dstAddress') {
            loadAddress('dstAddress');
        }
    }, [debounceTimeDst, addressType.dstAddress, toggle, addressTypeVisited.dstAddress]);

    const debounceTimeAlt1 = useDebounce(searchString.alternateDelAddress1);
    React.useEffect(() => {
        if (addressTypeRadio === 'alternateDelAddress1') {
            loadAddress('alternateDelAddress1');
        }
    }, [debounceTimeAlt1, addressType.alternateDelAddress1, toggle, addressTypeVisited.alternateDelAddress1]);


    const debounceTimeAlt2 = useDebounce(searchString.alternateDelAddress2);
    React.useEffect(() => {
        if (addressTypeRadio === 'alternateDelAddress2') {
            loadAddress('alternateDelAddress2');
        }
    }, [debounceTimeAlt2, addressType.alternateDelAddress2, toggle, addressTypeVisited.alternateDelAddress2]);

    const debounceTimeRedirection = useDebounce(searchString.redirectionAddress);
    React.useEffect(() => {
        if (addressTypeRadio === 'redirectionAddress') {
            loadAddress('redirectionAddress');
        }
    }, [debounceTimeRedirection, addressType.redirectionAddress, toggle, addressTypeVisited.redirectionAddress]);

    const debounceTimebilling = useDebounce(searchString.billToDetails);
    React.useEffect(() => {
        if (addressTypeRadio === 'billToDetails') {
            loadAddress('billToDetails');
        }
    }, [debounceTimebilling, addressType.billToDetails, toggle, addressTypeVisited.billToDetails]);

    const debounceTimeReturn = useDebounce(searchString.retAddress);
    React.useEffect(() => {
        if (addressTypeRadio === 'retAddress') {
            loadAddress('retAddress');
        }
    }, [debounceTimeReturn, addressType.retAddress, toggle, addressTypeVisited.retAddress]);

    const debounceTimeConsignor = useDebounce(searchString.consignorAddress);
    React.useEffect(() => {
        if (addressTypeRadio === 'consignorAddress') {
            loadAddress('consignorAddress');
        }
    }, [debounceTimeConsignor, addressType.consignorAddress, toggle, addressTypeVisited.consignorAddress]);

    const updateConsignorAddress = (data: any) => {
        setConsignorAddressDocument({
            id: 'consignorAddress',
            name: data[ConsignorName.key],
            addressLine1: data[ConsignorAddressLine1.key],
            addressLine2: data[ConsignorAddressLine2.key],
            cityName: data[ConsignorCity.key],
            stateName: data[ConsignorState.key],
            countryName: data[ConsignorCountry.key],
            pincode: data[ConsignorPincode.key],
            phone: data[ConsignorPhone.key],
            email: data[ConsignorEmail.key],
            alternatePhone: '',
            locationId: '',
            locationCode: '',
        });
    };
    React.useEffect(() => {
        if (!isInternational) return;
        if (consignorAddressType === ConsignorAddressType.addressBook) return;
        if (addressTypeRadio === 'srcAddress'
            || addressTypeRadio === 'retAddress'
            || addressTypeRadio === 'consignorAddress') {
            const updateData = {
                ...formData,
                ...(form.getFieldsValue() || {}),
            };
            updateConsignorAddress(updateData);
        }
    }, [
        Form.useWatch(ConsignorName.key, form),
        Form.useWatch(ConsignorAddressLine1.key, form),
        Form.useWatch(ConsignorAddressLine2.key, form),
        Form.useWatch(ConsignorCity.key, form),
        Form.useWatch(ConsignorState.key, form),
        Form.useWatch(ConsignorCountry.key, form),
        Form.useWatch(ConsignorPincode.key, form),
        Form.useWatch(ConsignorPhone.key, form),
        Form.useWatch(ConsignorEmail.key, form),
        formData.sellerDetails,
    ]);

    React.useEffect(() => {
        prefillData();
    }, []);

    React.useEffect(() => {
        if (sameChecked.billToDetails) {
            setFormData({
                ...formData,
                billToDetails: formData.dstAddress,
            });
            form.setFieldsValue({
                billToDetails: formData.dstAddress,
            });
            setSelected({
                ...selectedAddress,
                billToDetails: formData.dstAddress,
            });
        }
    }, [formData.dstAddress]);
    React.useEffect(() => {
        if (!(isInternational && consignorAddressDocument)) return;
        const consignorAddressToSet = consignorAddressType === ConsignorAddressType.profile
            ? consignorAddressDocument : formData.consignorAddress;
        const objToUpdate: any = {};
        if (sameChecked.srcAddressConsignor) {
            objToUpdate.srcAddress = consignorAddressToSet;
        }
        if (sameChecked.retAddressConsignor) {
            objToUpdate.retAddress = consignorAddressToSet;
        }
        if (Object.keys(objToUpdate)) {
            setFormData({
                ...formData,
                ...objToUpdate,
            });
            form.setFieldsValue({
                ...objToUpdate,
            });
            setSelected({
                ...selectedAddress,
                ...objToUpdate,
            });
        }
    }, [
        formData.consignorAddress,
        consignorAddressDocument,
        sameChecked.srcAddressConsignor,
        sameChecked.retAddressConsignor,
    ]);
    React.useEffect(() => {
        if (!isInternational) return;
        if (sameChecked.retAddressSrc) {
            setFormData({
                ...formData,
                retAddress: formData.srcAddress,
            });
            form.setFieldsValue({
                retAddress: formData.srcAddress,
            });
            setSelected({
                ...selectedAddress,
                retAddress: formData.srcAddress,
            });
        }
    }, [formData.srcAddress]);

    React.useEffect(() => {
        if (!isInternational) return;
        if (consignorAddressType === ConsignorAddressType.profile) {
            // To refresh the selected address
            setSelected((currentState) => ({
                ...currentState,
                consignorAddress: consignorAddressDocument as PickupAddress,
            }));
        }
    }, [formData.consignorAddress]);

    const renderRowDescription = (FieldIcon: React.ElementType, label: string) => {
        return (
            <div className={classes.boxTitle}>
                <div className={classes.boxIcon}>
                    <FieldIcon className={classes.boxIcon} />
                </div>
                <span>{label}</span>
            </div>
        );
    };

    const isCurrentAddressSelected = (key: string, item: PickupAddress) => {
        return Boolean(selectedAddress && selectedAddress[key] && item.id === selectedAddress[key]?.id);
    };

    const handleDelete = async (e: any, id: string) => {
        Helper.stops(e);
        const result = await deleteAddress({ id });
        if (result.isSuccess) {
            message.success('Address Deleted Successfully');
            setToggle(!toggle);
        } else {
            message.error(result.errorMessage);
        }
    };

    const renderDelete = (item: PickupAddress, key: string) => {
        const isDisabled = (!isCurrentAddressSelected(key, item) && formData?.isEditingInternationalCN);
        return (
            <Popconfirm
                placement="topLeft"
                title={t('delete_address_text')}
                onConfirm={(e) => handleDelete(e, item.id)}
                okText={t('ok_text')}
                cancelText={t('cancel')}
                onCancel={(e) => Helper.stops(e as any)}
                disabled={isDisabled}
            >
                <DeleteOutlined
                    onClick={(e) => {
                        Helper.stops(e as any);
                    }}
                    style={{ color: isDisabled ? 'gray' : 'red' }}
                />
            </Popconfirm>
        );
    };

    const renderEdit = (item: PickupAddress, key: string) => {
        const isDisabled = (!isCurrentAddressSelected(key, item) && formData?.isEditingInternationalCN);
        return (
            <EditOutlined
                onClick={(e) => {
                    if (isDisabled) return;
                    Helper.stops(e as any);
                    setAddressType({
                        ...addressType,
                        [key]: 'createnew',
                    });
                    form.setFieldsValue({
                        [`addressOption${key}`]: 'createnew',
                    });
                    setUpdateAddress(true);
                    setPluginAddressType(key);
                    setEditAddress(item);
                }}
                style={{ color: isDisabled ? 'gray' : uiTheme.primaryColor, marginRight: '0 8px' }}
            />
        );
    };

    const renderMarkAsDefaultMultiple = (item: PickupAddress) => {
        const isMultipleDefault = addressTypeRadio === 'srcAddress' ? item?.isDefaultSrc : item?.isDefaultDst;
        return (
            <>
                { isMultipleDefault ? (
                    <Button
                        style={{ cursor: 'default' }}
                        shape="round"
                    >
                        Default
                    </Button>
                ) : null}
                { isMultipleDefault ? (
                    <Popconfirm
                        placement="topLeft"
                        title="Are you sure you want to remove this default Address"
                        onConfirm={(e) => {
                            Helper.stops(e as any);
                            saveDefaultAddress({
                                ...item,
                                isDefaultSrc: addressTypeRadio === 'srcAddress' ? false : item?.isDefaultSrc,
                                isDefaultDst: addressTypeRadio !== 'srcAddress' ? false : item?.isDefaultDst,
                            }, true);
                        }}
                        onCancel={(e) => Helper.stops(e as any)}
                    >
                        <BookOutlined
                            style={{ color: 'red' }}
                            onClick={(e) => { Helper.stops(e as any); }}
                        />
                    </Popconfirm>
                ) : (
                    <BookOutlined
                        onClick={(e) => {
                            Helper.stops(e as any);
                            saveDefaultAddress({
                                ...item,
                                isDefaultSrc: addressTypeRadio === 'srcAddress' ? true : item?.isDefaultSrc,
                                isDefaultDst: addressTypeRadio !== 'srcAddress' ? true : item?.isDefaultDst,
                            }, true);
                        }}
                        style={{ color: uiTheme.primaryColor, marginRight: 8 }}
                    />
                )}
            </>
        );
    };

    const renderMarkAsDefault = (item: PickupAddress) => {
        if (defaultMultipleLimit) {
            return renderMarkAsDefaultMultiple(item);
        }
        // eslint-disable-next-line no-underscore-dangle
        if (
            (Object.keys(defaultAddress).length > 0
              && page === 'Consignee'
              && (defaultAddress._id === item.id || defaultAddress._id === item._id))
              || (Object.keys(defaultAddressSrc).length > 0
              && page === 'Consignor'
              && (defaultAddressSrc._id === item.id || defaultAddressSrc._id === item._id))
        ) {
            return (
                <Button
                    style={{ cursor: 'default' }}
                    shape="round"
                >
                    Default
                </Button>
            );
        }
        return (
            <Button
                type="link"
                onClick={(e) => {
                    Helper.stops(e as any);
                    saveDefaultAddress(item);
                }}
                style={{ color: uiTheme.primaryColor, marginRight: 8 }}
            >
                SET AS DEFAULT
            </Button>
        );
    };
    const showaddcode = (item: PickupAddress) => {
        return (
            <span style={{ display: 'flex', fontWeight: 'bold' }}>
                <span>{item?.addressCode}</span>
                <span style={{ margin: '0px 6px 0px 4px', fontWeight: 'bold' }}> | </span>
            </span>
        );
    };
    const updateFormData = (newData: any) => {
        setFormData(
            {
                ...formData,
                previousSelectedAddress: {
                    ...formData.previousSelectedAddress,
                    [pluginAddressType]: newData,
                },
            },
        );
    };

    const renderAddressActions = (item: PickupAddress, key: string) => {
        const showEditButtons = !['oldDstAddress', 'oldSrcAddress'].includes(item?.id);
        const removeActionButtons = ['consignorAddress'].includes(item?.id);
        if (removeActionButtons) {
            return <div style={{ justifyContent: 'flex-end' }} />;
        }
        return showEditButtons ? (
            <div style={{ justifyContent: 'flex-end' }}>
                {renderMarkAsDefault(item)}
                {renderEdit(item, key)}
                {renderDelete(item, key)}
            </div>
        ) : (
            <div style={{ justifyContent: 'flex-end' }}>
                {renderEdit(item, key)}
            </div>
        );
    };

    const selectDefaultAddress = (key: string) => {
        // eslint-disable-next-line no-underscore-dangle, max-len
        const item: PickupAddress | undefined = addressList[key].find((add) => (
            (Object.keys(defaultAddress).length > 0
            && (add.id === defaultAddress?._id || add._id === defaultAddress?._id))
            || (Object.keys(defaultAddressSrc).length > 0
            && (add.id === defaultAddressSrc?._id || add._id === defaultAddressSrc?._id))
        ));
        if (item) {
            setSelected({
                ...selectedAddress,
                [key]: item,
            });
            form.setFieldsValue({
                [key]: item,
            });
            setFormData({
                ...formData,
                [key]: item,
            });
        }
    };
    let check = false;
    React.useEffect(() => {
        // Check if no address is selected for a given key
        const key = addressKey === 'dstAddress' ? addressTypeRadio : addressKey;
        if (key === 'dstAddress' || key === 'srcAddress') {
            if (!selectedAddress[key]) {
                check = true;
            }
        }
        if (check) {
            selectDefaultAddress(key);
        }
    }, [addressList]);

    const renderCheckbox = (item: PickupAddress, key: string) => {
        return (
            <Checkbox
                key={item.id}
                checked={isCurrentAddressSelected(key, item)}
                className={classes.checkbox}
                disabled={formData?.isEditingInternationalCN}
            >
                <div className={classes.addressName}>
                    <span style={{ display: 'flex' }}>
                        {showAddressCode && item?.addressCode ? showaddcode(item) : null}
                        <span style={{ display: 'inline-block', fontWeight: 'bold' }}>
                            {item.name || ''}
                        </span>
                    </span>
                    {renderAddressActions(item, key)}
                </div>
            </Checkbox>
        );
    };

    const renderLine = () => {
        return (
            <div className={classes.line} />
        );
    };

    const renderCreateAddressButton = (key: string) => {
        return (
            <>
                <Button
                    type="link"
                    onClick={() => {
                        setAddressType({
                            ...addressType,
                            [key]: 'createnew',
                        });
                        setEditAddress(undefined);
                        form.setFieldsValue({
                            [`addressOption${key}`]: 'createnew',
                        });
                    }}
                    disabled={formData?.isEditingInternationalCN}
                >
                    +
                    {t('add_new_address')}
                </Button>
            </>
        );
    };

    const renderAddressSearch = (key: string) => {
        return (
            <div className={classes.searchAddress}>
                { addressTypeRadio === 'billToDetails'
                    ? (
                        <Checkbox
                            checked={sameChecked.billToDetails}
                            onChange={(e) => {
                                const currentChecked = {
                                    ...sameChecked,
                                    billToDetails: e.target.checked,
                                };
                                setSameChecked(currentChecked);
                                if (e.target.checked) {
                                    setSelected({
                                        ...selectedAddress,
                                        billToDetails: formData.dstAddress,
                                    });
                                    setFormData({
                                        ...formData,
                                        billToDetails: formData.dstAddress,
                                    });
                                    form.setFieldsValue({
                                        billToDetails: formData.dstAddress,
                                    });
                                }
                            }}
                            style={{
                                marginLeft: 8,
                                marginRight: 'auto',
                            }}
                        >
                            {t('use_same_as_delivery_address')}
                        </Checkbox>
                    )
                    : null}
                { addressTypeRadio === 'retAddress' && isInternational
                    ? (
                        <span
                            className={classes.flexColumn}
                            style={{
                                marginLeft: 8,
                                marginRight: 'auto',
                            }}
                        >
                            <Checkbox
                                checked={sameChecked.retAddressConsignor}
                                style={{ margin: 0 }}
                                onChange={(e) => {
                                    const currentChecked = {
                                        ...sameChecked,
                                        retAddressSrc: false,
                                        retAddressConsignor: e.target.checked,
                                    };
                                    if (e.target.checked) {
                                        setSelected({
                                            ...selectedAddress,
                                            retAddress: formData.consignorAddress,
                                        });
                                        setFormData({
                                            ...formData,
                                            retAddress: formData.consignorAddress,
                                        });
                                        form.setFieldsValue({
                                            retAddress: formData.consignorAddress,
                                        });
                                    } else {
                                        setFormData({
                                            ...formData,
                                            sameChecked: currentChecked,
                                        });
                                    }
                                    setSameChecked(currentChecked);
                                }}
                            >
                                Use Same as Consignor Address
                            </Checkbox>
                            <Checkbox
                                style={{ margin: 0 }}
                                checked={sameChecked.retAddressSrc}
                                onChange={(e) => {
                                    const currentChecked = {
                                        ...sameChecked,
                                        retAddressConsignor: false,
                                        retAddressSrc: e.target.checked,
                                    };
                                    if (e.target.checked) {
                                        setSelected({
                                            ...selectedAddress,
                                            retAddress: formData.srcAddress,
                                        });
                                        setFormData({
                                            ...formData,
                                            retAddress: formData.srcAddress,
                                        });
                                        form.setFieldsValue({
                                            retAddress: formData.srcAddress,
                                        });
                                    } else {
                                        setFormData({
                                            ...formData,
                                            sameChecked: currentChecked,
                                        });
                                    }
                                    setSameChecked(currentChecked);
                                }}
                            >
                                Use same as Pickup Address
                            </Checkbox>
                        </span>
                    )
                    : null}
                { addressTypeRadio === 'srcAddress' && isInternational
                    ? (
                        <Checkbox
                            checked={sameChecked.srcAddressConsignor}
                            onChange={(e) => {
                                const currentChecked = {
                                    ...sameChecked,
                                    srcAddressConsignor: e.target.checked,
                                };
                                if (e.target.checked) {
                                    setSelected({
                                        ...selectedAddress,
                                        srcAddress: formData.dstAddress,
                                    });
                                    setFormData({
                                        ...formData,
                                        srcAddress: formData.dstAddress,
                                    });
                                    form.setFieldsValue({
                                        srcAddress: formData.dstAddress,
                                    });
                                }
                                setSameChecked(currentChecked);
                            }}
                            style={{
                                marginLeft: 8,
                                marginRight: 'auto',
                            }}
                        >
                            Use Same as Consignor Address
                        </Checkbox>
                    )
                    : null}
                {renderCreateAddressButton(key)}
                <Input
                    placeholder={t('search_for_addresses')}
                    type="search"
                    onChange={(e) => setSearchString({
                        ...searchString,
                        [key]: e.target.value,
                        addType: key === 'srcAddress' ? 'Consignor' : 'Consignee',
                    })}
                    style={{ width: 200 }}
                />
            </div>
        );
    };

    const prettyPrintedNumber = (num: string) => {
        return num;
    };

    const renderAddressDetails = (item: PickupAddress) => {
        const customerCode = window.localStorage.getItem('userCode');
        return (
            <div className={classes.addressLineDetails}>
                <div style={{ minWidth: '30%', display: 'flex', flexDirection: 'column' }}>
                    {
                        item.addressCategory
                        && (
                            <span>
                                Address Category:
                                {item.addressCategory}
                            </span>
                        )
                    }
                    {
                        item.companyName
                        && (
                            <span>
                                Company Name:
                                {item.companyName}
                            </span>
                        )
                    }
                    <span>
                        {item.addressLine1 || ''}
                        {item.addressLine1 ? ', ' : ''}
                        {item.addressLine2 || ''}
                    </span>
                </div>
                <div style={{
                    minWidth: '30%',
                    marginLeft: isRTL ? undefined : '10%',
                    marginRight: isRTL ? '10%' : undefined,
                }}
                >
                    {item.pincode || ''}
                    {item.pincode && item.cityName ? ' - ' : ''}
                    {item.cityName || ''}
                    <br />
                    {item.stateName || ''}
                    {item.stateName ? ', ' : ''}
                    {item.countryName || ''}
                </div>
                <div style={{ minWidth: '30%', textAlign: 'right' }}>
                    {item.phone
                        ? (
                            <>
                                {prettyPrintedNumber(item.phone)}
                                <br />
                            </>
                        ) : null}
                    {item.alternatePhone
                        ? (
                            <>
                                {prettyPrintedNumber(item.alternatePhone)}
                                <br />
                            </>
                        ) : null}
                    {item.email ? item.email : null}
                    <b>{`${item.locationId || ''}`}</b>
                    <br />
                    <b>{item.locationId ? item.locationCode || `${customerCode}_${item.locationId}` : ''}</b>
                </div>
            </div>
        );
    };

    const renderAddressLine = (item: PickupAddress, key: string) => {
        let className = classes.addressLine;
        const isCurrentSelected = isCurrentAddressSelected(key, item);
        if (isCurrentSelected) {
            className = [classes.addressLineSelected, classes.addressLine].join(' ');
        }
        return (
            <div
                key={item.id}
                onClick={(e) => {
                    if (formData?.isEditingInternationalCN) return;
                    if (isInternational) {
                        const currentChecked = {
                            ...sameChecked,
                        };
                        let isUpdated = false;
                        if (addressTypeRadio === 'billToDetails') {
                            currentChecked.billToDetails = false;
                            isUpdated = true;
                        }
                        if (addressTypeRadio === 'srcAddress') {
                            currentChecked.srcAddressConsignor = false;
                            isUpdated = true;
                        }
                        if (addressTypeRadio === 'consignorAddress') {
                            isUpdated = true;
                            setConsignorAddressType(ConsignorAddressType.addressBook);
                            form.setFieldsValue({
                                [ConsignorName.key]: item.name,
                                [ConsignorAddressLine1.key]: item.addressLine1,
                                [ConsignorAddressLine2.key]: item.addressLine2,
                                [ConsignorCity.key]: item.cityName,
                                [ConsignorState.key]: item.stateName,
                                [ConsignorCountry.key]: item.countryName,
                                [ConsignorPincode.key]: item.pincode,
                                [ConsignorPhone.key]: item.phone,
                                [ConsignorEmail.key]: item.email,
                            });
                            setFormData({
                                ...formData,
                                consignorAddress: item,
                                ...(sameChecked.srcAddressConsignor ? { srcAddress: item } : {}),
                                ...(sameChecked.retAddressConsignor ? { retAddress: item } : {}),
                            });
                        }
                        if (addressTypeRadio === 'retAddress') {
                            currentChecked.retAddressConsignor = false;
                            currentChecked.retAddressSrc = false;
                            isUpdated = true;
                        }
                        if (isUpdated) {
                            setSameChecked(currentChecked);
                        }
                        if (page === consignmentLocationTypes.CONSIGNEE) {
                            const addressCategory = t(`consignee_type_${item?.addressCategory?.toLowerCase()}`);
                            form.setFieldsValue({
                                consigneeCompanyName: item?.companyName,
                                destinationType: addressCategory,
                            });
                        } else if (page === consignmentLocationTypes.CONSIGNOR) {
                            form.setFieldsValue({
                                consignorCompanyName: item?.companyName,
                                originType: item?.addressCategory?.toLowerCase(),
                            });
                        }
                    }
                    Helper.stops(e as any);
                    // remove selection if clicked on same row
                    if (isCurrentSelected) {
                        setSelected({
                            ...selectedAddress,
                            [key]: null,
                        });
                        form.setFieldsValue({
                            [key]: undefined,
                        });
                        setFormData({
                            ...formData,
                            [key]: undefined,
                        });
                    } else {
                        // save address
                        setSelected({
                            ...selectedAddress,
                            [key]: item,
                        });
                        form.setFieldsValue({
                            [key]: item,
                        });
                        setFormData({
                            ...formData,
                            [key]: item,
                        });
                    }
                }}
                className={className}
            >
                {renderCheckbox(item, key)}
                {renderAddressDetails(item)}
            </div>
        );
    };

    const renderAddressLineProfileSelected = (item: PickupAddress) => {
        return (
            <div
                key={item.id}
                className={`${classes.addressLineSelected} ${classes.addressLine}`}
            >
                <Checkbox
                    key={item.id}
                    checked
                    className={classes.checkbox}
                >
                    <div className={classes.addressName}>
                        <span style={{ display: 'flex' }}>
                            <span style={{ display: 'inline-block', fontWeight: 'bold' }}>
                                {item.name || ''}
                            </span>
                        </span>
                    </div>
                </Checkbox>
                {renderAddressDetails(item)}
            </div>
        );
    };

    const renderAddressList = (key: string) => {
        if (loading) {
            return <Loader zIndex={10} />;
        }
        return (
            <div className={classes.addressList}>
                {
                    isInternational
                    && consignorAddressDocument
                    && (shouldUseSenderProfile ? true : consignorAddressType === ConsignorAddressType.profile)
                    && key !== 'consignorAddress'
                        ? renderAddressLine({
                            ...consignorAddressDocument,
                            name: `${consignorAddressDocument.name || ''} | Consignor Address`,
                        }, key)
                        : null
                }
                {
                    isInternational
                    && consignorAddressDocument
                    && consignorAddressType === ConsignorAddressType.profile
                    && key === 'consignorAddress'
                        ? renderAddressLineProfileSelected({
                            ...consignorAddressDocument,
                            name: `${consignorAddressDocument.name || ''} | Consignor Address`,
                        })
                        : null
                }
                {addressList[key].map((item: PickupAddress) => renderAddressLine(item, key))}
            </div>
        );
    };

    const renderExisting = (key: string) => {
        return (
            <>
                {renderAddressSearch(key)}
                {renderLine()}
                {renderAddressList(key)}
                {renderLine()}
            </>
        );
    };

    const renderAddressFormUtil = (key: string) => {
        if (addressType[key] === 'createnew') {
            return (
                <>
                    <Button onClick={() => {
                        setAddressType({
                            ...addressType,
                            [key]: 'saved',
                        });
                        setEditAddress(undefined);
                        form.setFieldsValue({
                            [`addressOption${key}`]: 'saved',
                        });
                    }}
                    >
                        {t('Back')}
                    </Button>
                    <CreateAddress
                        editData={editAddress}
                        updateFormData={updateFormData}
                        isEditingInternationalCN={formData?.isEditingInternationalCN}
                        page={page}
                        onClose={() => {
                            setAddressType({
                                ...addressType,
                                [key]: 'saved',
                            });
                            setEditAddress(undefined);
                            form.setFieldsValue({
                                [`addressOption${key}`]: 'saved',
                            });
                        }}
                        phoneRegex={phoneRegex}
                        isInternationalAddress={
                            isInternational && addressTypeRadio !== 'srcAddress'
                        // || (editAddress?.isInternational || false)
                        }
                        isInternationalConsignment={formData.consignmentCategory === 'international'}
                        countryWisePhoneRegex={countryWisePhoneRegex}
                    />
                </>
            );
        }
        return renderExisting(key);
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const renderAddressForm = (key: string) => {
        return (
            <>
                {renderAddressFormUtil(key)}
            </>
        );
    };


    const complete = () => {
        return (
            <CheckCircleFilled
                style={{
                    color: '#27B479',
                    fontSize: 24,
                    margin: '0px 10px',
                }}
            />
        );
    };

    const inComplete = () => {
        return (
            <ExclamationCircleFilled
                style={{
                    color: '#EA2626',
                    fontSize: 24,
                    margin: '0px 10px',
                }}
            />
        );
    };

    const completeIncompleteIcon = (key: string) => {
        const active = key === addressTypeRadio;
        if (!addressListVisited[key] || active) {
            return (
                <div
                    className={classes.tabIcon}
                    style={{
                        border: active ? `2px solid ${uiTheme.primaryColor}`
                            : '2px solid #CCCCCC',
                        backgroundColor: active ? uiTheme.primaryColor : '#FFF',
                    }}
                >
                    <div className={classes.filledCircle} />
                </div>
            );
        }
        if (!selectedAddress[key]) {
            if (key === 'consignorAddress' && consignorAddressType === ConsignorAddressType.profile) return complete();
            return inComplete();
        }
        return complete();
    };

    const renderConsigneeCompanyName = () => {
        return (
            <div className={classes.consigneeDetailsInput}>
                <Form.Item
                    name="consigneeCompanyName"
                    rules={[
                        { validator: Helper.lengthAndAsciiValidator(3, 75) },
                        ...[consigneeType === t('consignee_type_business') ? formRuleRequired : {}],
                    ]}
                >
                    <Input
                        placeholder={t('renderConsigneeCompanyName')}
                        type="input"
                        onChange={(e) => form.setFieldsValue({
                            consigneeCompanyName: e.target.value,
                        })}
                        style={{ width: 200, height: 32 }}
                        disabled={formData?.isEditingInternationalCN}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderReceiverVATNumber = () => {
        return (
            <div className={classes.consigneeDetailsInput}>
                <Form.Item
                    name="receiverVATNumber"
                >
                    <Input
                        placeholder={t('receiver_vat_number_placeholder')}
                        type="input"
                        onChange={(e) => form.setFieldsValue({
                            receiverVATNumber: e.target.value,
                        })}
                        style={{ width: 200, height: 32 }}
                        disabled={formData?.isEditingInternationalCN}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderDestinationType = () => {
        const consigneeTypeOptions = [
            { label: t('consignee_type_individual'), value: 'individual' },
            { label: t('consignee_type_business'), value: 'business' },
        ];
        return (
            <div className={classes.consigneeDetailsInput}>
                <Form.Item
                    name="destinationType"
                    rules={isDestinationTypeRequired ? [formRuleRequired] : undefined}
                >
                    <Radio.Group
                        options={consigneeTypeOptions}
                        onChange={(e) => {
                            setConsigneeType(e.target.value);
                            form.setFieldsValue({
                                destinationType: e.target.value,
                            });
                        }}
                        style={{ width: 300, height: 32 }}
                        disabled={formData?.isEditingInternationalCN}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderConsigneeDetails = (FieldIcon: React.ElementType, label: string) => {
        return (
            <>
                <div className={classes.box}>
                    {renderRowDescription(FieldIcon, label)}
                    <div className={classes.consigneeDetails}>
                        <div className={classes.flexRow}>
                            {fieldsToShowInternational.consigneeCompanyName ? (
                                <div className={classes.flexColumn}>
                                    <div style={{ marginBottom: 8 }}>
                                        {t('consignee_company_name')}
                                        {consigneeType === 'Business' ? '*' : ''}
                                    </div>
                                    {renderConsigneeCompanyName()}
                                </div>
                            ) : null}
                            {fieldsToShowInternational.receiverVATNumber ? (
                                <div className={classes.flexColumn}>
                                    <div style={{ marginBottom: 8 }}>
                                        {t('receiver_vat_number')}
                                    </div>
                                    {renderReceiverVATNumber()}
                                </div>
                            ) : null}
                            {fieldsToShowInternational.destinationType ? (
                                <div className={classes.flexColumn}>
                                    <div style={{ marginBottom: 8 }}>
                                        {t('consignee_type')}
                                        {isDestinationTypeRequired ? '*' : ''}
                                    </div>
                                    {renderDestinationType()}
                                </div>
                            ) : null}
                        </div>
                        { renderLine() }
                    </div>
                </div>
            </>
        );
    };

    const renderAddress = () => {
        const field = { ...AddressTypes };
        const showAlternate = showAlternateAddress
            && addressKey === 'dstAddress' && formData.consignmentCategory === 'domestic';
        if (addressKey === 'dstAddress') {
            if (showAlternate) {
                field.options = field.options?.filter((option) => option.value !== 'srcAddress');
            } else {
                field.options = field.options?.filter((option) => (
                    option.value === 'dstAddress' || option.value === 'billToDetails'
                ));
            }
            if (formData.consignmentCategory !== 'international') {
                field.options = field.options?.filter((option) => option.value !== 'billToDetails');
            }
        } else if (isInternational) {
            if (shouldUseSenderProfile) {
                field.options = [
                    {
                        label: 'Pickup Address',
                        value: 'srcAddress',
                    },
                    {
                        label: 'Return Address',
                        value: 'retAddress',
                    },
                ];
            } else {
                field.options = [
                    {
                        label: 'Consignor Address*',
                        value: 'consignorAddress',
                    },
                    {
                        label: 'Pickup Address',
                        value: 'srcAddress',
                    },
                    {
                        label: 'Return Address',
                        value: 'retAddress',
                    },
                ];
            }
        } else {
            field.options = field.options?.filter((option) => option.value === 'srcAddress');
        }
        if (Array.isArray(field.options) && field.options.length > 0) {
            field.options.forEach((item: any) => {
                /* eslint-disable no-param-reassign */
                item.label = isInternational ? t(item.label) : t(item.value);
            });
        }
        return (
            <>
                { isInternational && page === 'Consignee'
                    ? renderConsigneeDetails(AddressIcon, t(`${(page || 'Consignee').toLowerCase()}_details`))
                    : null }
                <div className={classes.box}>
                    { formData.consignmentCategory !== 'international'
                        ? (renderRowDescription(AddressIcon, pageTitle))
                        : <div className={classes.boxTitle} /> }
                    <div className={classes.addressMenu}>
                        {field.options?.map((address) => {
                            return (
                                <div
                                    key={address.value}
                                    onClick={() => {
                                        setAddressTypeRadio(address.value);
                                        setAddressTypeVisited({ ...addressTypeVisited, [address.value]: true });
                                        setAddressListVisited({
                                            ...addressListVisited,
                                        });
                                    }}
                                    className={
                                        address.value === addressTypeRadio
                                            ? classes.addressItemSelected : classes.addressItem
                                    }
                                >
                                    {completeIncompleteIcon(address.value)}
                                    {address.label}
                                </div>
                            );
                        })}
                    </div>
                    <div className={classes.addressDetails}>
                        <div className={classes.addressBox}>
                            {renderAddressForm(addressTypeRadio)}
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <div className={classes.main}>
            {renderAddress()}
        </div>
    );
};
const mapStateToProps = (state: ReduxStore) => {
    return {
        uiTheme: state.uiTheme,
    };
};
const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectRouter: true,
    connectTranslession: true,
};


export default withStyles(stepTwoStyles)(GenericHoc(hocConfig)(StepTwo));
