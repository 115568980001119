import {
    Button,
    Drawer,
    Form,
    FormInstance,
    Input,
    InputNumber,
    Select,
    Row,
    Col,
} from 'antd';
import Cross from '../../../assets/cross';

import * as React from 'react';
import withStyles from 'react-jss';
import { StylesProps } from '../../../theme/jss-types';
import { itemDetailsStyles } from '../single-consignment.styles';
import { itemFormFields, isinternationalDisabledKey } from '../create-modal.constants';
import { FormField, InputTypes } from '../create-consignment.types';
import TextArea from 'antd/lib/input/TextArea';
import { formRules } from 'library/constants';
import { LabelValue } from 'library/Types';
import { useTranslation } from 'react-i18next';

interface PieceDetails {
    key: any;
    height?: any;
    length?: any;
    numberOfPieces?: any;
    unit?: any;
    weight?: any;
    weightUnit?: any;
    dimensionsUnit?: any;
    width?: any;
    declaredValue?: any;
    description?: any;
    items?: Record<any, any>;
}

interface StepTwoProps extends StylesProps<ReturnType<typeof itemDetailsStyles>> {
    selectedItem: any;
    onClose: any;
    pieces: any;
    formData: any,
    setFormData: any;
    selectedPieceKey: any;
    form: FormInstance;
    setPieces: any;
    removeItem: any;
    copyPreviousItem: any;
    addItem: any;
    mandatoryItemDetails: Record<any, boolean>;
    countryList: LabelValue[];
    updateFieldsValue: any;
}

const ItemDetails = (props: StepTwoProps) => {
    const {
        classes,
        selectedItem,
        onClose,
        pieces,
        formData,
        setFormData,
        selectedPieceKey,
        setPieces,
        form,
        mandatoryItemDetails,
        countryList,
        updateFieldsValue,
        removeItem,
        copyPreviousItem,
        addItem,
    } = props;
    const {
        HSNCode,
        OriginOfGoods,
        // SKU_Number,
        // ATE_Number,
        Prod_URL,
        Item_Rate,
        Duty_Value,
        Gst_Value,
        GST_Percentage,
        Prod_dead_weight,
        Prod_height,
        Prod_length,
        Prod_width,
        Composition,
        Prod_Type,
        // RODTEP_Scheme,
        Item_Size,
        // Cess_Value,
        no_of_items,
        item_Description,
    } = itemFormFields;
    const { t, i18n } = useTranslation();
    const formRuleRequired = {
        ...formRules.required,
        message: i18n.exists('required') ? t('required') : 'Required',
    };

    const [showDetails, setShowDetails] = React.useState<any>({});
    const prefillData = () => {
        // const itemPrefilData = get(pieces[selectedPieceKey]?.items || {}, selectedItem, {});
        const itemPrefilData = {
            pieces,
        };
        form.setFieldsValue(itemPrefilData);
    };

    React.useEffect(() => {
        prefillData();
    }, [pieces]);

    const autoCalculatedValues = (index: string) => {
        const formValue = form.getFieldsValue();
        const quantity = formValue.pieces[selectedPieceKey].items[index].numberOfItems;
        const itemValue = formValue.pieces[selectedPieceKey].items[index].itemValue;
        const gstPercentage = formValue.pieces[selectedPieceKey].items[index].gstPercentage;
        const dutyValue = quantity && itemValue ? quantity * itemValue : undefined;
        const gstValue = gstPercentage && dutyValue ? gstPercentage * dutyValue * 0.01 : undefined;
        const obj = {
            pieces: {
                [selectedPieceKey]: {
                    items: {
                        [index]: {
                            dutyValue: dutyValue
                                ? Number(dutyValue.toFixed(3)) + Number((gstValue || 0).toFixed(3))
                                : dutyValue,
                            gstValue: gstValue ? Number(gstValue.toFixed(3)) : gstValue,
                        },
                    },
                },
            },
        };
        form.setFieldsValue(obj);
    };

    const getMergedData = (existingData: any, newValues: any) => {
        const mergedData = { ...existingData, ...newValues };
        // keeping existing pieces data
        mergedData.pieces = existingData.pieces || {};
        // updating each item with upcoming new values
        Object.keys(newValues.pieces?.[selectedPieceKey]?.items || {}).forEach((itemKey) => {
            const item = newValues.pieces?.[selectedPieceKey]?.items?.[itemKey];
            if (mergedData.pieces?.[selectedPieceKey]?.items?.[itemKey]) {
                mergedData.pieces[selectedPieceKey].items[itemKey] = {
                    ...mergedData.pieces[selectedPieceKey].items[itemKey],
                    ...item,
                };
            } else {
                mergedData.pieces[selectedPieceKey].items[itemKey] = {
                    ...item,
                    key: itemKey,
                };
            }
        });
        return mergedData;
    };

    React.useEffect(() => {
        prefillData();
    }, []);

    const getFormRules = (field: FormField) => {
        const rules: any = [];
        const IntegerOnlyKeys: string[] = [no_of_items.key];
        if (mandatoryItemDetails[field.key]) rules.push(formRuleRequired);
        if (IntegerOnlyKeys.includes(field.key)) rules.push(formRules.number);
        return rules;
    };

    const renderInput = (field: FormField, index: string) => (
        <Form.Item
            name={['pieces', selectedPieceKey, 'items', index, field.key]}
            initialValue={field.defaultValue}
            valuePropName={field.valuePropName}
            rules={mandatoryItemDetails[field.key] ? [formRuleRequired] : undefined}
        >
            <Input
                type={field.type}
                min={0}
                placeholder={field.placeholder}
                style={{ width: field.width }}
                value={field.defaultValue?.toString()}
                width={field.width}
                disabled={(formData?.isEditingInternationalCN && isinternationalDisabledKey(field))}
            />
        </Form.Item>
    );

    const renderInputNumber = (field: FormField, index: string) => (
        <Form.Item
            name={['pieces', selectedPieceKey, 'items', index, field.key]}
            initialValue={field.defaultValue}
            valuePropName={field.valuePropName}
            rules={getFormRules(field)}
        >
            <InputNumber
                type={field.type}
                min={0}
                placeholder={field.placeholder}
                style={{ width: field.width }}
                width={field.width}
                disabled={field.disabled || (formData?.isEditingInternationalCN && isinternationalDisabledKey(field))}
                onChange={
                    ['itemValue', 'gstPercentage', 'numberOfItems', 'dutyValue'].includes(field.key)
                        ? () => autoCalculatedValues(index) : undefined
                }
            />
        </Form.Item>
    );

    const renderTextArea = (field: FormField, index: string) => (
        <Form.Item
            name={['pieces', selectedPieceKey, 'items', index, field.key]}
            initialValue={field.defaultValue}
            valuePropName={field.valuePropName}
            rules={mandatoryItemDetails[field.key] ? [formRuleRequired] : undefined}
        >
            <TextArea
                placeholder={field.placeholder}
                style={{ width: field.width }}
                value={field.defaultValue?.toString()}
                disabled={(formData?.isEditingInternationalCN && isinternationalDisabledKey(field))}
            />
        </Form.Item>
    );

    const renderFormItem = (field: FormField | undefined, index: string) => {
        if (!field) {
            return (
                <div style={{ padding: '10px 5%', width: '50%', display: 'inline-block' }} />
            );
        }
        switch (field.type) {
            case InputTypes.Input:
                return (
                    <Col flex={field.span}>
                        <div>
                            {field.label}
                            {mandatoryItemDetails[field.key] ? '*' : ''}
                        </div>
                        <div style={{}}>{renderInput(field, index)}</div>
                    </Col>
                );
            case InputTypes.Number:
                return (
                    <Col flex={field.span}>
                        <div>
                            {field.label}
                            {mandatoryItemDetails[field.key] ? '*' : ''}
                        </div>
                        <div style={{ width: '120px' }}>{renderInputNumber(field, index)}</div>
                    </Col>
                );
            case InputTypes.Textarea:
                return (
                    <Col flex={field.span}>
                        <div>
                            {field.label}
                            {mandatoryItemDetails[field.key] ? '*' : ''}
                        </div>
                        {renderTextArea(field, index)}
                    </Col>
                );
            case InputTypes.Radio:
                // eslint-disable-next-line no-case-declarations
                const options = field.options ? [...field.options, ...countryList] : countryList;
                return (
                    <Col flex={field.span}>
                        <div>
                            {field.label}
                            {mandatoryItemDetails[field.key] ? '*' : ''}
                        </div>
                        <Form.Item
                            name={['pieces', selectedPieceKey, 'items', index, field.key]}
                            initialValue={field.defaultValue || 'INDIA'}
                            valuePropName={field.valuePropName}
                            rules={mandatoryItemDetails[field.key] ? [formRuleRequired] : undefined}
                        >
                            <Select
                                showSearch
                                options={options}
                                style={{ width: field.width }}
                                defaultValue="INDIA"
                                disabled={(formData?.isEditingInternationalCN && isinternationalDisabledKey(field))}
                            />
                        </Form.Item>
                    </Col>
                );
            default:
                return null;
        }
    };

    const renderButtonSave = () => {
        return (
            <Form.Item>
                <Button
                    type="primary"
                    onClick={async () => {
                        const check: any[] = [];
                        Object.keys(mandatoryItemDetails).forEach((fieldKey) => {
                            if (mandatoryItemDetails[fieldKey]) {
                                Object.keys(formData?.pieces?.[selectedPieceKey]?.items || {}).forEach((itemKey) => {
                                    check.push(['pieces', selectedPieceKey, 'items', itemKey, fieldKey]);
                                });
                            }
                        });
                        await form.validateFields(check);
                        const updatedData = getMergedData(formData, form.getFieldsValue());
                        Object.keys(updatedData.pieces || []).forEach((pieceKey) => {
                            const piece = updatedData.pieces[pieceKey];
                            piece.declaredValue = 0;
                            Object.keys(piece.items || []).forEach((itemKey) => {
                                const item = piece.items[itemKey];
                                piece.declaredValue += (item.dutyValue || 0);
                            });
                        });
                        setFormData(updatedData);
                        const updatedPieces: Record<any, PieceDetails> = {};
                        Object.keys(updatedData.pieces).forEach((key) => {
                            updatedPieces[key] = {
                                ...updatedData.pieces[key],
                                key,
                            };
                        });
                        setPieces(updatedPieces);
                        updateFieldsValue(updatedData);
                        onClose(true);
                    }}
                    style={{ marginRight: 16 }}
                    htmlType="submit"
                    // loading={creating}
                >
                    Save
                </Button>
            </Form.Item>
        );
    };

    const renderHeader = () => {
        return (
            <div className={classes.header}>
                <div className={classes.flexRow}>
                    <Cross
                        onClick={() => onClose(false)}
                        alt="close"
                        className={classes.closeIcon}
                    />
                    <span>Add Item</span>
                </div>
                {/* {renderButtonSave()} */}
                <Button
                    style={{
                        margin: '5px',
                        color: 'blue',
                        borderColor: 'blue',
                    }}
                    onClick={async () => {
                        const check: any[] = [];
                        Object.keys(mandatoryItemDetails).forEach((fieldKey) => {
                            if (mandatoryItemDetails[fieldKey]) {
                                Object.keys(formData?.pieces?.[selectedPieceKey]?.items || {}).forEach((itemKey) => {
                                    check.push(['pieces', selectedPieceKey, 'items', itemKey, fieldKey]);
                                });
                            }
                        });

                        await form.validateFields(check);

                        const updatedData = getMergedData(formData, form.getFieldsValue());

                        const updatedPieces: Record<string, PieceDetails> = {};
                        Object.keys(updatedData.pieces).forEach((key) => {
                            updatedPieces[key] = {
                                ...updatedData.pieces[key],
                                key,
                            };
                        });
                        setPieces(updatedPieces);
                        addItem(updatedPieces[selectedPieceKey]);
                    }}

                >
                    Add Item
                </Button>
            </div>
        );
    };


    const renderFooter = () => {
        return (
            <div className={classes.footer}>
                {renderButtonSave()}
            </div>
        );
    };
    const toggleDetails = (itemId: string) => {
        setShowDetails((prevState: { [key: string]: boolean }) => ({
            ...prevState,
            [itemId]: !prevState[itemId],
        }));
    };

    return (
        <div>
            <Drawer
                visible
                width="95%"
                title={renderHeader()}
                height="100vh"
                // onClose={onClose}
                className={classes.main}
                closable={false}
            >
                <Form
                    form={form}
                    style={{ paddingBottom: 50 }}
                >
                    {pieces[selectedPieceKey]?.items
                        && Object.keys(pieces[selectedPieceKey]?.items).map((itemId, index) => (
                            <div key={itemId} className={classes.formItemContainer}>
                                <Row gutter={[10, 16]} justify="center" align="middle">
                                    <Col span={0.1}>{index + 1}</Col>
                                    {renderFormItem(item_Description, itemId)}
                                    {renderFormItem(HSNCode, itemId)}
                                    {renderFormItem(OriginOfGoods, itemId)}
                                    {renderFormItem(Item_Rate, itemId)}
                                    {renderFormItem(no_of_items, itemId)}
                                    {renderFormItem(GST_Percentage, itemId)}
                                    {renderFormItem(Gst_Value, itemId)}
                                    {renderFormItem(Duty_Value, itemId)}
                                </Row>
                                {showDetails[itemId] && (
                                    <div style={{ paddingLeft: '20px' }}>
                                        <Row gutter={[10, 16]} justify="start" align="middle">
                                            {renderFormItem(Prod_URL, itemId)}
                                            {renderFormItem(Prod_dead_weight, itemId)}
                                            {renderFormItem(Item_Size, itemId)}
                                            {renderFormItem(Prod_length, itemId)}
                                            {renderFormItem(Prod_width, itemId)}
                                            {renderFormItem(Prod_height, itemId)}
                                            {renderFormItem(Composition, itemId)}
                                            {renderFormItem(Prod_Type, itemId)}
                                        </Row>
                                        {/* <Row gutter={[10, 16]} justify="center" align="middle">
                                            {/* {renderFormItem(SKU_Number, itemId)}
                                            {{renderFormItem(Cess_Value, itemId)} }
                                            {renderFormItem(ATE_Number, itemId)}
                                {renderFormItem(RODTEP_Scheme, itemId)}
                                        </Row> */}
                                    </div>
                                )}
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button
                                        style={{
                                            borderRadius: '50px',
                                            margin: '5px',
                                            color: 'blue',
                                            border: 'none',
                                        }}
                                        onClick={() => toggleDetails(itemId)}
                                    >
                                        {showDetails[itemId] ? 'Hide Additional Details' : 'Show Additional Details'}
                                    </Button>
                                    <Button
                                        style={{ borderRadius: '50px', margin: '5px' }}
                                        onClick={async () => {
                                            const check: any[] = [];
                                            Object.keys(mandatoryItemDetails).forEach((fieldKey) => {
                                                if (mandatoryItemDetails[fieldKey]) {
                                                    check.push([
                                                        'pieces',
                                                        selectedPieceKey,
                                                        'items',
                                                        selectedItem,
                                                        fieldKey,
                                                    ]);
                                                }
                                            });

                                            await form.validateFields(check);

                                            const updatedData = getMergedData(formData, form.getFieldsValue());

                                            const updatedPieces: Record<string, PieceDetails> = {};
                                            Object.keys(updatedData.pieces).forEach((key) => {
                                                updatedPieces[key] = {
                                                    ...updatedData.pieces[key],
                                                    key,
                                                };
                                            });
                                            setPieces(updatedPieces);
                                            copyPreviousItem(updatedPieces[selectedPieceKey]);
                                        }}

                                    >
                                        Duplicate
                                    </Button>
                                    <Button
                                        style={{ borderRadius: '50px', margin: '5px', color: 'red' }}
                                        onClick={() => removeItem(selectedPieceKey, itemId)}
                                    >
                                        Delete
                                    </Button>
                                </div>
                            </div>
                        ))}
                </Form>
                {renderFooter()}
            </Drawer>
        </div>
    );
};

export default withStyles(itemDetailsStyles)(ItemDetails);
